.modal-new {
  .modal-header {
    border-bottom: none;
  }

  .DocumentsHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 28px;
      text-transform: capitalize;
      color: #000000;
      margin: 0;
    }
  }

  table {
    thead {
      tr {
        margin-bottom: 25px;

        th {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 25px;
          color: #1c212d;
        }
      }
    }

    tbody {
      tr {
        td {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 23px;
          color: #1c212d;
          position: relative;

          &.DocNameData {
            font-weight: 600;
          }

          .Details {
            display: inline-block;
            width: 315px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        &:not(:last-child) td {
          border-bottom: 1px solid rgba(28, 33, 45, 0.1);
        }
      }
    }
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 25px;
    width: 25px;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 50%;
    height: 25px;
    width: 25px;
    background-color: transparent;
    transform: translateX(-50%);
    border-radius: 5px;
    border: 2px solid #000000;
  }

  input[type="checkbox"]:checked~.checkmark {
    background-color: #00bc8b;
    border-color: #00bc8b;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input[type="checkbox"]:checked~.checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 8px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .save-btn {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #ffffff;
  }

  .Cancel-btn {
    background: rgb(128 142 167 / 20%);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #000000;
    border: none;
  }

  textarea[rows] {
    height: auto;
  }

  .upload-input-wrap.documnet-attachment.plus-open {
    background: #1b1b1b;
    border-radius: 5.66667px;
    height: 100%;
    min-height: 100px;
  }

  .tab-inner-box {
    .tab-inner-box-left {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #1c1c1c;
    }

    .tab-inner-box-right {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
    }
  }

  .inner-header {
    font-weight: 600;
  }

  .status-btn {
    button {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #0363ee;
      padding: 0;
      border: none;
      display: inline-flex;
      align-items: center;

      &::after {
        content: "";
        display: inline-flex;
        width: 10.34px;
        height: 5.17px;
        margin-left: 12px;
      }
    }
  }

  .sub-points {

    input[type="checkbox"],
    .checkmark {
      top: 0;
      left: 0;
      transform: none;
    }

    label {
      padding-left: 25px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
    }
  }

  .radio-btn-wrap {
    label {
      position: relative;
      padding-left: 30px;

      /* Hide the browser's default radio button */
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      /* Create a custom radio button */
      .checkmark-radio {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: transparent;
        border-radius: 50%;
        border: 2px solid #000000;
      }

      /* On mouse-over, add a grey background color */
      input~.checkmark-radio {
        background-color: transparent;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark-radio:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the indicator (dot/circle) when checked */
      input:checked~.checkmark-radio {
        border-color: #00bc8b;
      }

      input:checked~.checkmark-radio:after {
        display: block;
      }

      /* Style the indicator (dot/circle) */
      .checkmark-radio:after {
        top: 50%;
        left: 50%;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #00bc8b;
        transform: translate(-50%, -50%);
      }
    }
  }

  .image-uploaded img {
    height: 110px;
    object-fit: contain;
    object-position: center;
    background: #ffffff;
  }
}

.fade.right-modal.modal-new {
  .modal-content {
    .modal-footer {
      // padding-right: 0.425rem;
    }
  }
}

.modal-backdrop.fade {
  opacity: 0.4;
}

.role-modal {
  table {
    thead {
      tr {
        th:not(:first-child) {
          text-align: center;
        }
      }
    }
  }

  .role-label {
    padding-left: 40px;

    .form-group {
      position: relative;
    }

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 25px;
      width: 25px;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: transparent;
      border-radius: 5px;
      border: 2px solid #000000;
    }

    input[type="checkbox"]:checked~.checkmark {
      background-color: #00bc8b;
      border-color: #00bc8b;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    input[type="checkbox"]:checked~.checkmark:after {
      display: block;
    }

    .checkmark:after {
      left: 8px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 23px;
      color: #000000;
      padding-left: 15px;
    }
  }
}

.fade.right-modal.modal-new.project-term-modal,
.fade.right-modal.modal-new.modal-add-project-user {
  max-width: 22.5%;
}

.fade.right-modal.modal-new .modal-content .modal-header .modal-title {
  line-height: 28px;
}

.image-uploaded {
  overflow: hidden;
  border-radius: 5.66667px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.modal-add-project.modal-new {
  .sub-points {

    input[type="checkbox"],
    .checkmark {
      top: 10px;
    }

    label {
      padding-left: 35px;
    }
  }
}

.modal-create-task {
  .project-details {
    .form-floating {
      background: #ffffff;
      border-radius: 100%;
      padding: 2px;

      &:not(:first-child) {
        margin-left: -10px;
      }
    }
  }
}

.modal-create-task.modal .modal-content {
  .modal-footer{
    bottom: -20px;
  }
  label {
    font-size: 20px;
    display: inline-block;
    font-weight: 500;
    margin-bottom: 14px;
    color: #000000;
  }
}
button.btn.btn-password{
  position: relative;
}
button.btn.btn-password .spinner-border {
  position: absolute;
  left: 6px;
  top: 14px;
}