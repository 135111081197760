.icon-btn-lg {
  border: 1px solid transparent;
  border-radius: 5px;
  width: 35px;
  height: 35px;
  padding: 0;
  transition: all 0.4s;
  background: transparent;

  i {
    font-size: 1rem;
  }

  &:hover {
    background-color: transparent !important;
    border: 1px solid currentColor !important;
  }
  &.btn-primary{
    background: transparent; 
    border: none;
    color: $icon-btn-btn-primary;
    min-width: 26px;
    height: 34px;
    line-height: 34px;
    &.active,
    &:active{
      background-color:transparent;
      border-color: transparent !important;
      color: $icon-btn-btn-primary;
    }
    &:first-child:active{
      background-color:transparent;
      border-color: transparent;
      color: $icon-btn-btn-primary;
      &:focus-visible{
        box-shadow: none;
      }
    }
  }
}

.icon-btn {
  background: transparent;
  color: $icon-btn-old-silver-color;
  border-color: transparent;
  height: 30px;
  width: 30px;
  padding: 0;
  font-size: 18px;
  &:hover {
    color: $icon-btn-old-silver-color !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  &.btn-primary{
    background: transparent; 
    border: none;
    min-width: 26px;
    height: 26px;
    &.active,
    &:active{
      background-color:transparent !important;
      border-color: transparent;
    }
    &:first-child:active{
      background-color:transparent;
      border-color: transparent;
      &:focus-visible{
        box-shadow: none;
      }
    }
  }
}

.btn-primary{
  background-color:#00BC8B;
  border-color: #00BC8B;
  &:hover{
    background-color:#00BC8B;
    border-color: #00BC8B;
  }
  &.active,
  &:active{
    background-color:#00BC8B !important;
    border-color: #00BC8B;
  }
  &:first-child:active{
    background-color:#00BC8B;
    border-color: #00BC8B;
    &:focus-visible{
      box-shadow: none;
    }
  }
  :not(.btn-check)+.btn:active {
       background-color:#00BC8B;
       border-color: #00BC8B;
     }
     &:disabled{
      background-color:#00BC8B;
      border-color: #00BC8B;
     }
}

.btn-secondary{
  background-color: #e6e8ed;
  border-color: #e6e8ed;
  color: #000000;
  &:hover{
    background-color: #e6e8ed;
    border-color: #e6e8ed;
    color: #000000;
  }
  &.active,
  &:active{
    background-color: #e6e8ed;
    border-color: #e6e8ed;
    color: #000000;
  }
  &:first-child:active{
    background-color: #e6e8ed;
    border-color:#e6e8ed;
    color: #000000;
    &:focus-visible{
      box-shadow: none;
    }
  }
  :not(.btn-check)+.btn:active {
    background-color: #e6e8ed;
    border-color: #e6e8ed;
  }
}


.inner-search-bar {
  position: relative;
  margin-right: 10px;
  .btn.search-button {
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 19px;
    color: #9da1a7 !important;
    transform: translateY(-50%);
    text-align: center;
    padding: 7px 15px;
  }
  .form-control{
    color: #9da1a7;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    padding: 0 10px 0 48px;
    height: 44px;
    border-radius: 10px;
    border-color: transparent;
  }
  input::placeholder {
    color: $light-silver-color;
    opacity: 1;
  }
  input:-ms-input-placeholder {
    color: $light-silver-color;
  }
  input::-ms-input-placeholder {
    color: $light-silver-color;
  }
  &.inner-search-grey{
    .form-control{
      background-color: #F3F6F9;
    }
  }
}
