.breadcrumb {
  background-color: transparent;
  padding: 0rem 1rem 0.75rem 0rem;
  font-weight: 500;
  align-items: center;
  .breadcrumb-item {
    &.active {
      color: $oxford-blue-breadcrumb-color;
    }

    & + .breadcrumb-item {
      &::before {
        content: "\7c" !important;
        color: $oxford-blue-breadcrumb-color;
      }

      & ~ .breadcrumb-item {
        &::before {
          content: "\3e" !important;
          color: $oxford-blue-breadcrumb-color;
        }
      }
    }
    
  }
}

nav ol li,
nav ol li a {
  color: $oxford-blue-breadcrumb-color;
}
