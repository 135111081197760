table.table {
  border-collapse: separate;
  border-spacing: 0 5px;
  border: 0;
  width: 100%;
  font-size: 14px;
  padding-bottom: 3px;
  margin-bottom: 10px;
  tr {
    height: 35px;
    &.table-hight-remove {
      height: unset;
      min-height: 50px;
    }
    th,
    td {
      vertical-align: middle;
      // padding: 0 0.75rem;
      padding: 0 0.625rem;
      border-bottom: none;

      &:first-child {
        // border-top-left-radius: 5px;
        // border-bottom-left-radius: 5px;
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
        // border-top-right-radius: 5px;
        // border-bottom-right-radius: 5px;
      }
    }
  }

  & > thead > tr {
    th {
      // background: $table-th-bg;
    }
  }
  & > tbody > tr {
    td {
      // background: $table-td-bg;
      &.padding-top-bottom {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
      }
    }
    .project-short-img {
      border-radius: 50px;
      // background: $table-img-bg-color;
      background-color: #f3f5fd;
      // padding: 5px;
      margin-right: 10px;
      font-weight: 600;
      font-size: 20px;
      line-height: 45px;
      text-transform: capitalize;
      text-align: center;
      color: #1c212d;
      padding: 1px;
    }
    // .task-name {
    //   font-weight: 500;
    //   font-size: 18px;
    //   line-height: 27px;
    //   color: $black-color;
    // }
    // .project-team {
    //   font-weight: 400;
    //   font-size: 16px;
    //   line-height: 24px;
    //   color: $table-img-bg-color;
    // }
  }
  .leave-reason {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #1c212d;
  }
  .leave-types {
    span {
      width: 34px;
      height: 34px;
      display: inline-block;
      line-height: 25px;
      border-radius: 5px;
      text-align: center;
      font-weight: 500;
      font-size: 19px;
      text-transform: uppercase;
      padding: 5px;
    }
    .leave-sl {
      color: $black-color;
      background-color: $topaz-bg;
    }
    .leave-cl {
      color: $white-color;
      background-color: $blue-bg;
    }
  }
  .apply-date {
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: rgba(28, 33, 45, 0.5);
  }
  .leave-types {
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
  }
  .project-time {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
  }

  &.leave-request-table {
    border-spacing: 0;
    tbody {
      tr {
        td {
          border-top: 2px solid #f3f6f9;
        }
      }
    }
  }
  &.table-inner-section {
    border-spacing: 0;
    tbody {
      tr {
        td {
          border-bottom: 1px solid #dce0e4;
          .task-time {
            color: rgba(28, 33, 45, 0.5);
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
        }
      }
    }
    .project-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: rgba(28, 33, 45, 0.5);
    }
    .project-description {
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: #1c212d;
    }
  }
}

.recent-task-section {
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
}

.leave-request-col {
  margin-top: 20px;
}
.table-header-col {
  margin-bottom: 20px;
}

.project-img {
  border-radius: 50%;
  margin-top: 5px;
  margin-bottom: 5px;
  object-fit: cover;
  line-height: 2.5;
  text-align: center;
  font-size: 12px;
  &.bg-color-1 {
    background-color: $bg-color-1;
  }

  &.bg-color-2 {
    background-color: $bg-color-2;
  }

  &.bg-color-3 {
    background-color: $bg-color-3;
  }

  &.bg-color-4 {
    background-color: $bg-color-4;
  }

  &.bg-color-5 {
    background-color: $bg-color-5;
  }

  &.bg-color-6 {
    background-color: $bg-color-6;
  }

  &.bg-color-7 {
    background-color: $bg-color-7;
  }
}

.left-border-default {
  position: relative;
  &:before {
    border-left: 1px solid #cccccc;
    content: "";
    width: 1px;
    position: absolute;
    left: -7px;
    height: 30px;
  }
}

/*table dataTable*/

.dataTables_filter {
  width: 100%;
  margin-bottom: 15px;
  label {
    font-size: 0;
    width: 100%;
    input[type="search"] {
      font-size: 15px;
      width: 100%;
      margin: 0;
      background: $white-bg;
      padding: 0 0.75rem;
      height: 35px;
      font-weight: 500;
      border-color: $top-search-border-color;
      border-radius: 0.375rem;
      &:focus {
        color: $bs-body-color;
        background: $white-bg;
        border-color: $top-search-border-focus-color;
        outline: 0;
      }
      &::-webkit-search-cancel-button {
        display: none;
      }
    }
    &:before {
      content: "\f002";
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      color: #09254f;
      position: absolute;
      right: 0;
      z-index: 1;
      font-size: 1rem;
      top: 0;
      padding: 5px 11px;
      cursor: pointer;
      border: 1px solid transparent;
      border-radius: 5px;
    }
    &:hover {
      &:before {
        border-color: $black-color;
      }
    }
    &::after {
      content: "";
      width: 2px;
      position: absolute;
      height: 25px;
      right: 40px;
      background-color: $bright-gray-color;
      top: 5px;
    }
  }
}

table {
  &.dataTable {
    padding-bottom: 15px;
    border-spacing: 0;
    thead {

      >tr {
        th{
          &.sorting {
            &:before {
              // opacity: 1;
              // bottom: 33px;
              line-height: 15px;
            }
            &:after {
              // opacity: 1;
              // top: 18px;
              line-height: 15px;
            }
          }
        }
        >th.sorting_asc {
          &:before {
            opacity: 1;
          }
        }
        >th.sorting_desc {
          &:after {
            opacity: 1;
          }
        }
        >td.sorting_asc {
          &:before {
            opacity: 1;
          }
        }
        >td.sorting_desc {
          &:after {
            opacity: 1;
          }
        }
      }
      
      // &.sorting_asc,
      // &.sorting_desc {
      //   &:before {
      //     opacity: 1;
      //   }
      //   &:after {
      //     opacity: 1;
      //   }
      // }
      // &.sorting_desc,
      // &.sorting_asc, {
      //   &:before {
      //     opacity: 1;
      //   }
      //   &:after {
      //     opacity: 1;
      //   }
      // }
    }
    &.no-footer {
      border-bottom: none;
    }
  }
}

.dataTables_wrapper {
  margin-bottom: 20px;
  .dataTables_paginate {
    .paginate_button {
      padding: 0.3em 0.9em;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      &:hover{
          border: 1px solid $white-bg;
          background: $white-bg;
          background-color: $white-bg;
          color: #000000 !important;
      }
      &:active {
        background: $white-bg;
        box-shadow: none;
      }
      &.current {
        border: 1px solid $white-bg;
        background: $white-bg;
        background-color: $white-bg;
        &:hover {
          border: 1px solid $white-bg;
          background: $white-bg;
          background-color: $white-bg;
        }
      }
      &.previous{
        font-size: 0;
        width: 35px;
        position: relative;
        cursor: pointer;
        border: none;
        &:active {
          background: transparent;
          box-shadow: none;
        }
        &:hover{
          border: none;
          background: transparent;
        }
        &.disabled{
          &::before{
            opacity: 0.3;
          }
        }
        &::before{
          content: "";          
          background: url("../../../Images/down-arrow.svg") no-repeat center center;
          width: 25px;
          height: 20px;
          display: inline-block;
          transform: rotate(90deg);
          position: absolute;
          top: -5px;
          left: 3px;
        }
      }
      &.next{
        font-size: 0;
        position: relative;
        width: 35px;
        cursor: pointer;
        border: none;
        &:active {
          background: transparent;
          box-shadow: none;
        }
        &:hover{
          border: none;
          background: transparent;
        }
        &.disabled{
          &::before{
            opacity: 0.3;
          }
        }
        &::before{
          content: "";          
          background: url("../../../Images/down-arrow.svg") no-repeat center center;
          width: 25px;
          height: 20px;
          display: inline-block;
          transform: rotate(-90deg);
          position: absolute;
          top: -5px;
          right: 3px;
        }
      }
    }
  }
  .dataTables_length {
    select {
      background-color: $white-bg;
      border: 1px solid $paginate-color;
      &:focus-visible {
        outline: none;
      }
    }
  }
  .dataTables_info,
  .dataTables_length {
    font-size: 15px;
  }
}

.dataTables_paginate {
  font-size: 15px;
  &.paging_simple_numbers {
    span {
      display: inline-block;
    }
  }
}

.tbl-info {
  .length-info {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;

    .dataTables_length {
      margin-top: auto;
      margin-bottom: auto;
      float: unset;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      select{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        border-color: #ffffff;
      }
    }

    .dataTables_info {
      padding-top: 0;
      margin-top: auto;
      margin-bottom: auto;
      clear: unset;
      float: unset;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    .dataTables_paginate {
      float: unset;
      padding-top: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

/*table dataTable*/

/*new design table*/

.table-section-col {
  background-color: #ffffff;
  border-radius: 20px;
  padding: 20px;

  .project-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
  }
}

.table-inner-section-col {
  background-color: #f3f6f9;
  border-radius: 20px;
  padding: 20px;
}

/*new design table*/

.table-col {
  .status-icon{
    margin-right: 10px;
  }
  .table-text-common {
    span{
      margin-left: 15px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
      color: #1C212D;
    }
  }
}