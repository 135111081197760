.login-page {
  height: 100vh;
  background: url("../../Images/auth-bg-img.jpg") no-repeat center bottom/cover;
  .login-box {
    &:after {
      content: "";
      background: $login-bg;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
  .login-left-col {
    position: relative;
    height: 100vh;
    .login-left-content {
      color: $white-color;
      position: absolute;
      top: 22%;
      transform: translate(-25%, -50%);
      max-width: 405px;
      text-align: center;
      margin: 0 auto;
      right: 0;
      left: 50%;
      display: inline-table;
      img {
        width: 300px;
      }
      .login-welcome {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        letter-spacing: 0.25em;
        color: $white-color;
        position: relative;
        text-transform: uppercase;
        margin: 20px auto 0;
        max-width: 300px;
      }
    }
  }
  .login-right-col {
    max-width: 450px;
    width: 100%;
    background: $white-bg;
    border-radius: 20px;
    padding: 30px;
    position: relative;
    z-index: 2;
    -webkit-box-shadow: 1px 1px 38px 19px rgb(255 255 255 / 50%);
    -moz-box-shadow: 1px 1px 38px 19px rgb(255 255 255 / 50%);
    box-shadow: 1px 1px 38px 19px rgb(255 255 255 / 50%);
    .login-content {
      font-weight: 600;
      font-size: 32px;
      line-height: 45px;
      color: $black-color;
      text-align: center;
      margin-bottom: 30px;
    }
    .login-logo {
      margin-bottom: 42px;
    }
  }
  .login-selection-data-area {
    margin-bottom: 40px;
    padding: 0;
    text-align: right;
    a {
      cursor: pointer;
      color: $sea-serpent-color;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.03em;
      &:hover {
        color: $eerie-clack-color;
      }
    }
  }
  .form-group {
    position: relative;
    margin-bottom: 35px;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: $black-color;
      -webkit-box-shadow: 0 0 0px 1000px $white-color inset !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }

    .form-control {
      height: 55px;
      padding: 10px 47px 10px 18px;
      border-color: $login-black-color;
      &:focus {
        box-shadow: none;
        border-color: $login-black-color;
      }
    }
    label {
      position: absolute;
      left: 12px;
      pointer-events: none;
      transition: 0.5s;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      text-align: center;
      letter-spacing: 0.03em;
      color: $login-black-label-color;
      background: $white-bg;
      padding: 0px 5px;
      top: -13px;
    }
    input:focus ~ label,
    input:valid ~ label {
      top: -13px;
      left: 12px;
      font-weight: 300;
      color: $login-black-label-color;
    }
    .passwordshow {
      position: absolute;
      top: 18px;
      right: 18px;
      cursor: pointer;
      i {
        color: $black-color;
        font-size: 19px;
      }
    }
    .gmail-icon {
      position: absolute;
      top: 18px;
      right: 18px;
      i {
        color: $black-color;
        font-size: 19px;
      }
    }
    &.password {
      margin-bottom: 5px;
    }
    &.confirmpassword {
      margin-top: 15px;
    }
  }
  .bottom-button {
    display: flex;
    gap: 20px;
    .btn {
      width: 50%;
      border: 1px solid $bottom-button-but-color !important;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      padding: 11px 20px;
      &.btn-cancel {
        &:hover {
          background: $bottom-button-but-color;
          transition: all 0.4s;
          color: $white-color;
        }
      }
      &.btn-password {
        background: $bottom-button-but-color;
        transition: all 0.4s;
        color: $white-color;
        border-radius: 5px;
        .fa-key-skeleton {
          padding-right: 5px;
        }
        &:hover {
          background: transparent;
          transition: all 0.4s;
          color: $bottom-button-but-color;
        }
      }
    }
  }

  .user-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    i {
      color: $black-color;
      font-size: 19px;
    }
  }
}
.btn.btn-for-login {
  width: 100%;
  background: $bottom-button-but-color;
  text-transform: capitalize;
  padding: 11px 10px;
  transition: all 0.4s;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.03em;
  color: $white-color;
  border-radius: 5px;
  &:hover {
    background: transparent;
    transition: all 0.4s;
    color: $bottom-button-but-color;
    border: 1px solid $bottom-button-but-color !important;
  }
}
.success-msg {
  color: $success-color;
  margin-top: 20px;
  text-align: center;
}
