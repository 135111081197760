$white-color:#ffffff;
$white-bg:$white-color;
$black-color:#000000;
$danger-color:#db4f50;
$light-silver-color:#9da1a7;
$bs-body-color: #212529;
$bs-body-bg:#F3F6F9;
$sidebar-bg:#242424;
$vertical-menu-icon-hover:#345BF8;
$modal-title-color:$vertical-menu-icon-hover;
$btn-primary:$vertical-menu-icon-hover;
$tab-color:$vertical-menu-icon-hover;
$header-bg:$sidebar-bg;
$header-search-bg:$header-bg;
$header-search-box-bg:rgba(0, 0, 0, 0.93);
$header-border-left-bg:#b8b8b8;  
$scrollbar-track-bg:#f1f1f1;
$scrollbar-thumb-bg:#c4c4c4;
$alice-blue-bg:#f3f5fd;
$time-label-dark-gray:#a9a9a9;
$blue-bg:#345bf8;
$ultramarine-blue-bg:#2858f3;
$eerie-black-bg:#1c1c23;
$corn-silk-bg:#fff9db;
$topaz-bg:#ffd178;
$go-green-bg:#14ae5c;
$pastel-orange-bg:#ffbc42;
$card-black-bg:rgba(0, 0, 0, 0.4);
$chinese-white-bg:rgba(225, 225, 225, 0.2);
$dark-tangerine-color:#f5aa13;
$quartz-color:#4A4A4A;
$bright-gray-border-color:#EFEFEF;
$timberwolf-color:#dcd5d5;
$lotion-search-input-bg:#fcfcfc;
$bright-maroon-color:#c0283f;
$fire-opal-color:#f15555;
$slate-gray-color:#757d8a;
$dark-orange-color:#ff8c00;
$dark-charcoal-color:#2e2e2e;
$bright-gray-color:#e9e9e9;
$green-label-bg:#00bc8b;
$red-label-bg:#db562c;
$oxford-blue-breadcrumb-color:#09254f;
$icon-btn-btn-primary:#09254f;
$icon-btn-old-silver-color:#828282;
$sea-serpent-color:#43c4dd;
$eerie-clack-color:#171c21;
$bottom-button-but-color:$eerie-clack-color;
$success-color:green;
$login-black-color:rgba(0, 0, 0, 0.2);
$login-black-label-color:rgba(0, 0, 0, 0.6);
$login-bg:rgba(0, 0, 0, 0.35);

$task-label-green-bg:#E6FEF1;
$task-label-blue-bg:#E6EEF9;


$table-th-bg:#ebebeb;
$table-td-bg:#f3f5fd;
$table-img-bg-color:#364250;
$top-search-border-focus-color:#86b7fe;
$top-search-border-color:#ced4da;
$paginate-color:$top-search-border-color;
$bg-color-1:#fdadbe;
$bg-color-2:#9f9ad0;
$bg-color-3:#e8c2a2;
$bg-color-4:#00c3e6;
$bg-color-5:#fec358;
$bg-color-6:#f8abaa;
$bg-color-7:#9ab9c3;