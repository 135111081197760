* {
    //   font-family: "poppins";
    font-family: "Source Sans Pro";
}

body {
    //   font-family: "poppins";
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    scrollbar-width: thin;
    color: $bs-body-color;
    background-color: $bs-body-bg;
}

:root {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

:root ::-webkit-scrollbar {
    display: none;
}

.scroll {
    height: calc(100vh - 120px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
}

.scroll::-webkit-scrollbar {
    display: none;
}

a,
a:not([href]):not([className]),
a:not([href]):not([className]):hover {
    color: inherit;
    text-decoration: none;
}

a:focus-visible {
    outline: none;
}

.form-control:focus {
    box-shadow: none;
}

/* scrollbar */

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    scrollbar-width: thin;
}

::-webkit-scrollbar-track {
    background: $scrollbar-track-bg;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-bg;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-bg;
}

/* scrollbar */

/*error*/
.text-danger-error {
    color: $danger-color;
    font-size: 12px;
    font-weight: 400;
    margin-top: 2px;
}

div.text-danger-error:empty {
    display: none;
}
/*error*/

.btn-secondary,
.btn-primary,
.btn-success,
.btn-danger {
    min-width: 139px;
    height: 44px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-radius: 10px;
}

.btn-secondary {
    margin-right: 10px;
}

.btn {
    &:hover,
    &:active {
        border-color: transparent !important;
    }
}

/*vertical-menu*/
.vertical-menu {
    background: $sidebar-bg;
    position: fixed;
    width: 100px;
    z-index: 5;
    white-space: nowrap;
    top: 20px;
    height: calc(100% - 40px);
    border-radius: 20px;
    margin-left: 20px;

    a {
        // color: $white-color;
        color: #808ea7;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .short-logo {
        // min-height: 90px;
        // padding: 15px 10px;
        min-height: 80px;
        padding: 15px 19px;
        height: 50px;
        position: relative;

        &::before {
            content: "";
            border: 1px solid #475060;
            position: absolute;
            bottom: 0;
            width: 70px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }

    .vertical-list {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 120px);
        overflow-x: hidden;
        scrollbar-width: none;

        li {
            list-style: none;
        }

        .vertical-menu-icon {
            margin-bottom: 10px;
            font-size: 20px;
            height: 60px;
            width: 60px;
            position: relative;
            border-radius: 10px;

            &.active {
                background: $vertical-menu-icon-hover;
                color: $white-color;
            }

            &:hover {
                background: $vertical-menu-icon-hover;
                color: $white-color;
            }
        }
    }
}

.header {
    //   background: $header-bg;
    //   padding: 0 1.375rem;
    //   min-height: 90px;
    position: fixed;
    top: 0;
    width: calc(100% - 160px);
    z-index: 9;
    background-color: #f3f6f9;
    padding-top: 20px;
    padding-bottom: 20px;
    //   &:before {
    //     content: "";
    //     background: #f3f6f9;
    //     display: block;
    //     height: 20px;
    //     position: absolute;
    //     top: -20px;
    //     width: 100%;
    //     left: 0;
    //   }

    .wrapper {
        width: 100%;
    }

    .wrapper .search-input {
        width: 100%;
        border-radius: 5px;
        position: relative;
    }

    .search-input input {
        height: 60px;
        width: 100%;
        outline: none;
        border: none;
        padding: 0 0 0 60px;
        z-index: 1;
        position: relative;
        border-bottom: none;
        margin-left: 0;
        background: $white-bg;
        padding-right: 10px;
        color: $light-silver-color;
        text-transform: capitalize;
        border-radius: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 23px;
    }

    .search-input input::placeholder {
        color: $light-silver-color;
        opacity: 1;
    }

    .search-input input:-ms-input-placeholder {
        color: $light-silver-color;
    }

    .search-input input::-ms-input-placeholder {
        color: $light-silver-color;
    }
    /**/
    .header-search-box {
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        // background: $header-search-box-bg;
        // background: rgba(0, 0, 0, 0.5);
        overflow: hidden scroll;
        height: 100vh;
        // padding: 75px 25px 25px 25px;
        // backdrop-filter: blur(4px);
        z-index: 5;
        background: rgba(0, 0, 0, 0.84);
        backdrop-filter: blur(100px);
        -webkit-backdrop-filter: blur(100px);
    }

    .search-input.z-adjust {
        position: relative;
        z-index: 6;
    }

    .top-header {
        .header-search-div {
            position: sticky;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            height: 107px;
            top: 0;
            left: 0;
            z-index: 9;
        }

        .btn-close {
            position: absolute;
            right: 140px;
            top: 35px;
            // background-color: $white-bg;
            background-size: 18px;
            z-index: 1;
            height: 40px;
            width: 40px;
            padding: 0;
            filter: brightness(0) invert(1);
            opacity: 1;
        }

        .container {
            padding-top: 30px;
        }

        .container {
            max-width: 100%;
            padding-left: 140px;
            padding-right: 140px;
        }
    }

    .header-search-modal {
        h2 {
            color: $white-color;
            margin-bottom: 14px;
            font-size: 28px;
            line-height: 34px;
        }

        .number {
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 5px;
        }

        .lables {
            display: block;
            padding: 7px 10px;
            border-radius: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
            min-width: 96px;

            &.project-lable {
                background: rgba(255, 255, 255, 0.3);
                color: $white-color;
            }
        }

        .project-img {
            background-color: #ffffff;
        }

        .card {
            background: $card-black-bg;
            border-color: transparent;
            color: $white-color;
        }

        .card-col {
            background: $card-black-bg;
            border-color: transparent;
            color: $white-color;
            border-radius: 10px;
            padding: 15px;

            .department {
                color: $white-color;
                padding: 7px 14px;
                background: $chinese-white-bg;
                border-radius: 5px;
                text-transform: capitalize;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
        }

        .emplo-img {
            margin-bottom: 6px;
        }

        .card-title {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            text-transform: capitalize;
            color: #49abff;
        }
    }
    /**/

    .search-input.active input {
        border-radius: 5px 5px 0 0;
    }

    .search-input .autocom-box {
        padding: 0;
        opacity: 0;
        pointer-events: none;
        max-height: 280px;
        overflow-y: auto;
        border-radius: 0 0 5px 5px;
        position: absolute;
        width: 100%;
    }

    .search-input.active .autocom-box {
        padding: 10px 8px;
        opacity: 1;
        pointer-events: auto;
    }

    .autocom-box li {
        list-style: none;
        padding: 8px 12px;
        display: none;
        width: 100%;
        cursor: default;
        border-radius: 3px;
    }

    .search-input.active .autocom-box li {
        display: block;
    }

    .search-input .icon {
        position: absolute;
        left: 0;
        top: 50%;
        font-size: 22px;
        cursor: pointer;
        z-index: 2;
        color: #9da1a7;
        transform: translateY(-50%);
        height: 60px;
        width: 60px;
        text-align: center;
        line-height: 65px;
    }

    .task-time-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding: 0 8px 0 20px;
        color: $white-color;
        // border-left: 2px solid $header-border-left-bg;
        margin: 11px 0 11px 20px;
    }

    .task-heading {
        text-transform: capitalize;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: $light-silver-color;
    }

    .task-content {
        min-width: 150px;
        max-width: 350px;
    }

    .task-project-title {
        margin-bottom: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .current-project {
        display: flex;
        text-transform: capitalize;
        margin-top: 5px;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }

    .header-project-col {
        margin-left: 20px;
        width: 50%;

        .content-project-col {
            width: 100%;
        }
    }

    .header-search {
        // margin-right: 20px;
        // width: calc(100% - 600px);
        max-width: 600px;
        width: 100%;
    }

    .current-project-no {
        margin-right: 10px;
        white-space: nowrap;
    }

    .task-time {
        // margin-left: 15px;
        text-align: right;

        .label-badge {
            padding: 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-transform: capitalize;
            color: $time-label-dark-gray;
        }
    }

    .task-time-working {
        span {
            font-weight: 700;
            font-size: 34px;
            line-height: 38px;
            text-align: center;
            color: #345bf8;
            background: #ffffff;
            border-radius: 10px;
            padding: 3px 13px;
            margin-left: 10px;
        }
    }

    .pause-play-button {
        // cursor: pointer;
        // font-size: 20px;
        // width: 44px;
        // height: 44px;
        // background-color: $blue-bg;
        // border-radius: 50%;

        cursor: pointer;
        font-size: 18px;
        width: 40px;
        height: 40px;
        background-color: #345bf8;
        border-radius: 50px;
        min-width: 40px;
        line-height: 1.2;
    }

    .header-project-col {
        .project-name {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: rgba(28, 33, 45, 0.5);
        }

        .project-description {
            font-weight: 700;
            font-size: 18px;
            line-height: 22px;
            color: #1c212d;
        }
    }

    .dashboard-title {
        .dashboard-main-title {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            text-align: right;
            color: #1c212d;
        }

        .dashboard-top-title {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            text-align: right;
            color: #1c212d;

            i {
                color: $dark-tangerine-color;
                margin-right: 4px;
            }
        }

        .user-profile-img {
            border: 1px solid #ffffff;
            margin-left: 10px;
            background-color: #ffffff;
            line-height: 55px;
            font-size: 25px;
        }
    }

    .notification {
        position: relative;

        .notification-col {
            position: relative;
            margin-right: 30px;

            &:after {
                content: "";
                border-right: 1px solid #c8ccd0;
                position: absolute;
                right: -30px;
                display: inline-block;
                height: 30px;
            }

            span {
                position: absolute;
                top: -10px;
                right: 0;
                background: #f04141;
                border: 1px solid #ffffff;
                display: inline-block;
                padding: 0px 6px;
                border-radius: 50px;
                font-weight: 700;
                font-size: 14px;
                line-height: 16px;
                color: #ffffff;
                min-width: 20px;
                min-height: 20px;
                text-align: center;
            }
        }

        .notification-header {
            font-weight: 700;
            font-size: 20px;
            line-height: 20px;
            color: #ffffff;
            border-bottom: 1px solid #ffffff;
            padding: 20px;
        }

        .custom-notification-body {
            position: absolute;
            right: 30px;
            top: 40px;
            min-width: 400px;
            background: #1c212d;
            z-index: 1;
            border-radius: 10px;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
            width: 100%;

            .notification-lists {
                padding: 0 20px 0 20px;
                max-height: 425px;
                overflow-y: scroll;

                .notification-list {
                    border-bottom: 1px solid #ffffff;
                    display: flex;
                    padding: 15px 0;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                .notification-img {
                    margin-right: 10px;
                }

                .notification-name {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;
                    color: #ffffff;
                    white-space: pre-wrap;
                }

                .notification-time {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #ffffff;
                }
            }
            // .custom-modal-footer {
            //   text-align: center;
            //   padding: 10px 8px;
            //   border-top: 1px solid #E5E5E5;
            //   .see-all-link {
            //     color: #345BF8;
            //     font-size: 16px;
            //     text-transform: capitalize;
            //   }
            // }
        }
    }

    .user-profile-img {
        background-color: #ffffff;
        margin-right: 10px;
        line-height: 35px;
        text-align: center;
        font-size: 16px;
        display: inline-block;
        color: #1c212d;
        font-weight: 600;
        text-transform: capitalize;
    }
}

.cards {
    padding: 20px;
    background: #ffffff;
    border-radius: 20px;

    .card {
        border-radius: 20px;
        border: none;
    }

    .card-body {
        padding: 30px 20px;
    }

    .card-title {
        color: $white-color;
        font-weight: 700;
        font-size: 22px;
        line-height: 50px;
        min-width: 80px;
        min-height: 80px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        margin-right: 30px;
        width: 80px;
        height: 80px;
        padding: 10px;
    }

    .cards-icon {
        margin: 0 auto;
        height: 90px;
        width: 90px;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 28px;
        display: flex;
    }

    .card-text {
        // color: $white-color;
        text-transform: capitalize;
        text-align: left;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        color: #000000;

        span {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
        }
    }

    .today-task {
        .card-title {
            background-color: #345bf8;
        }
    }

    .balance-leave {
        .card-title {
            background-color: #00bc8b;
        }
    }

    .profile-complete {
        .card-title {
            //   font-weight: 500;
            //   font-size: 36px;
            //   line-height: 49px;
            //   color: $black-color;
            background-color: #ff9e01;
        }

        .card-text {
            color: $black-color;
        }
    }
}

.main-content {
    // background: $white-color;
    // margin-left: 85px;
    margin-left: 140px;
    width: calc(100% - 160px);
    // min-height: 100vh;
    min-height: calc(100vh - 120px);
    height: calc(100vh - 20px);
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    scrollbar-width: none;
}

.page-header {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.5;
    color: $bs-body-color;
}

.width-60px {
    width: 60px;
    max-width: 60px;
}

.width-50px {
    width: 50px;
    max-width: 50px;
}

.width-75px {
    width: 75px;
    max-width: 75px;
}

.width-100px {
    width: 100px;
    max-width: 100px;
}

.width-125px {
    width: 125px;
    max-width: 125px;
}

.width-150px {
    width: 150px;
    max-width: 150px;
}

.width-180px {
    width: 180px;
    max-width: 180px;
}

.width-200px {
    width: 200px;
    min-width: 200px;
}

.width-250px {
    width: 250px;
    max-width: 250px;
}

.width-300px {
    width: 300px;
    max-width: 300px;
}

.width-350px {
    width: 350px;
    max-width: 350px;
}

.min-width-90px {
    min-width: 90px;
}

.min-width-111px {
    min-width: 111px;
}

.min-width-130px {
    min-width: 130px;
}

.position-adjust {
    position: absolute;
    top: 0;
    right: 0;
}

.search-bar {
    width: 100%;
    //   &::after {
    //     content: "";
    //     width: 2px;
    //     position: absolute;
    //     height: 25px;
    //     right: 35px;
    //     background-color: $bright-gray-color;
    //     top: 50%;
    //     transform: translateY(-50%);
    //   }

    &.project-search {
        margin-right: 0;
        max-width: 300px;

        .form-control {
            padding-left: 33px;
            width: 100%;
            background: $lotion-search-input-bg;
            border-color: $quartz-color;
            border-radius: 5px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $quartz-color;
        }

        .icon-btn-lg {
            &:hover {
                border: none !important;
            }
        }

        &::after {
            display: none;
        }

        .position-adjust {
            left: 0;
            min-width: 26px;
            height: unset;
            // top: 5px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    padding: 0 0.625rem;
}

.row {
    margin-top: 0;
    margin-right: -0.625rem;
    margin-left: -0.625rem;

    & > *,
    div[class^="col-"] {
        padding: 0 0.625rem;
    }
}

.project-details-top-col {
    display: flex;
    align-items: center;

    .project-name {
        font-size: 25px;
        font-weight: 600;
        line-height: 29px;
        text-transform: uppercase;
        margin-right: 20px;
        color: $bs-body-color;
    }
}

.project-details-left-col {
    .project-no {
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .customer-col {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }

    .customer-name {
        font-size: 16px;
        line-height: 19px;
        text-transform: capitalize;
        font-weight: 600;
        margin-right: auto;
        display: flex;
        align-items: center;

        i {
            font-size: 23px;
        }
    }

    .project-status-date {
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        text-transform: capitalize;

        .project-date-status {
            min-width: 200px;
            font-weight: 700;
        }

        .date {
            font-size: 14px;
            margin-top: 4px;
        }
    }

    .project-description {
        margin-top: 15px;

        .description {
            height: 120px;
            overflow: overlay;
            font-size: 15px;
            font-weight: 400;
            line-height: 23px;
            margin-bottom: 0;
            padding-right: 10px;
        }

        .form-label {
            font-size: 16px;
            font-weight: 700;
            text-transform: capitalize;
            line-height: 18px;
        }
    }
}

.recent-task-date-time {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .task-date-time {
        color: #8d8f95;
        text-transform: capitalize;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    .total-time {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        color: #1c212d;
    }
}

.project-carts-col {
    margin-top: 30px;
}

.project-title {
    white-space: nowrap;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 28px;
    line-height: 35px;
    color: #1c212d;
}

.project-last-days {
    text-align: center;
    margin-left: auto;
    font-size: 18px;
    text-transform: capitalize;
    font-weight: 600;
    display: flex;
    align-items: center;

    span {
        border: 2px solid $bright-maroon-color;
        color: $bright-maroon-color;
        display: flex;
        min-width: 34px;
        min-height: 34px;
        border-radius: 50px;
        font-size: 15px;
        margin-left: 10px;
        font-weight: 800;
        align-items: center;
        justify-content: center;
        line-height: normal;
    }
}

.task-label-badge {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.25;
    padding: 6px 9px;
    border-radius: 4px;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    color: $white-color;

    &:first-child {
        margin-left: 0 !important;
    }

    &.green {
        background-color: $task-label-green-bg;
        color: #009546;
    }

    &.red {
        background-color: $red-label-bg;
    }

    &.blue {
        background-color: $task-label-blue-bg;
        color: #0363ee;
    }

    &.dark-cyan {
        background-color: rgba(0, 139, 139, 0.1);
        background-color: #008b8b;
    }

    &.violet-red {
        background-color: rgba(232, 62, 140, 0.1);
        color: #e83e8c;
    }

    &.shamrock {
        color: #34c38f;
        background-color: rgba(52, 195, 143, 0.1);
    }

    &.light-green {
        background-color: rgba(154, 205, 50, 0.1);
        color: #9acd32;
    }

    &.teal {
        background-color: rgba(0, 130, 127, 0.1);
        color: #00827f;
    }

    &.cannon-pink {
        background-color: rgba(150, 80, 117, 0.1);
        color: #965075;
    }
}

/*label-badge*/
.label-badge {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    padding: 5px 15px;
    border-radius: 40px;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    color: $white-color;

    &:first-child {
        margin-left: 0 !important;
    }

    &.green {
        background-color: $green-label-bg;
    }

    &.red {
        background-color: $red-label-bg;
    }

    &.blue {
        background-color: $blue-bg;
    }

    &.dark-cyan {
        background-color: rgba(0, 139, 139, 0.1);
        background-color: #008b8b;
    }

    &.violet-red {
        background-color: rgba(232, 62, 140, 0.1);
        color: #e83e8c;
    }

    &.shamrock {
        color: #34c38f;
        background-color: rgba(52, 195, 143, 0.1);
    }

    &.light-green {
        background-color: rgba(154, 205, 50, 0.1);
        color: #9acd32;
    }

    &.teal {
        background-color: rgba(0, 130, 127, 0.1);
        color: #00827f;
    }
    // &.cannon-pink {
    //   background-color: rgba(150, 80, 117, 0.1);
    //   color: #965075;
    // }
    /*library*/
    &.js {
        background: rgba(205, 92, 92, 0.1);
        color: #cd5c5c;
    }

    &.react {
        background: rgba(255, 20, 147, 0.1);
        color: #ff1493;
    }

    &.net {
        background: rgba(255, 140, 0, 0.1);
        color: $dark-orange-color;
        // background-color: $dark-orange-color;
    }

    &.mobile {
        background: rgba(238, 130, 238, 0.1);
        color: #ee82ee;
        // background-color: #ee82ee;
    }

    &.angular {
        background: rgba(64, 224, 208, 0.1);
        color: #40e0d0;
        // background-color: #40e0d0;
    }

    &.flutter {
        background: rgba(46, 223, 255, 0.1);
        color: #2edfff;
        // background-color: #2edfff;
    }

    &.wordpress {
        background: rgba(123, 104, 238, 0.1);
        color: #7b68ee;
        // background-color: #7b68ee;
    }

    &.react-native {
        background: rgba(60, 179, 113, 0.1);
        color: #3cb371;
        // background-color: #3cb371;
    }

    &.seo {
        background: rgba(0, 128, 128, 0.1);
        color: #008080;
        // background-color: #008080;
    }

    &.laravel {
        background: rgba(218, 165, 32, 0.1);
        color: #daa520;
        // background-color: #daa520;
    }
}

// .project-label {
//     padding: 3px 9px;

//     border-radius: 5px;
//     font-weight: 700;
//     font-size: 16px;
//     line-height: 20px;

//     text-transform: uppercase;
//     text-align: center;
//     white-space: nowrap;
// }
/*label-badge*/

// .librarys {
//   display: grid;
//   grid-template-columns: repeat(5, minmax(min-content, max-content));
//   gap: 3px;
//   margin-top: 2px;
// }

.nav-tabs {
    border-bottom: 0;
    box-shadow: inset 0 -1px 0 $tab-color;

    .nav-link {
        border: none;
        font-size: 16px;
        font-weight: 600;
        line-height: 21px;
        background: transparent;
        color: $bs-body-color;
        margin-right: 10px;

        &.active {
            color: $tab-color;
            background-color: transparent;
            box-shadow: inset 0 -3px 0 $tab-color;
            margin-bottom: -1px;

            &:hover {
                color: $tab-color;
                background-color: transparent;
                box-shadow: inset 0 -3px 0 $tab-color;
                opacity: 1;
            }
        }

        &:hover {
            opacity: 0.5;
            color: $tab-color;
            background-color: transparent;
            box-shadow: inset 0 -3px 0 $tab-color;
        }
    }
}

.due-date {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 20px;
    font-weight: 500;
    color: $fire-opal-color;
}

.project-card {
    .icon-btn {
        font-size: 14px;
        min-width: 26px;
        height: 30px;
    }

    .card-title {
        margin-bottom: 0;
        text-transform: capitalize;
        font-size: 22px;
        font-weight: 500;
        line-height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 270px;
        color: $bs-body-color;
    }

    .card {
        border: none;
        height: 100%;
        background-color: $alice-blue-bg;

        .card-body {
            padding: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .project-details {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.setting-from {
    .project-details-left-col {
        .description {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        .form-select {
            font-size: 0.825rem;
        }
    }
}

.task-count-col {
    color: $slate-gray-color;
    background: $bright-gray-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    padding: 0 6px;

    &.archive {
        margin-right: 5px;
        padding: 0 4px;
        background-color: rgba(255, 140, 0, 0.1);
        color: $dark-orange-color;

        .task-icon {
            font-size: 14px;
        }
    }

    .task-icon {
        min-width: 24px;
        line-height: 30px;
        border-radius: 7px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;

        i {
            margin-right: 0;
        }
    }

    .task-count {
        min-width: 24px;
        min-height: 30px;
        line-height: 30px;
        border-radius: 7px;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
    }
}

.users {
    display: flex;
    align-items: center;

    .project-img {
        /*border: 2px solid $white-color;*/
        border: 1px solid #858585;
        margin-right: -7px;
        object-fit: cover;
        font-weight: 600;
        font-size: 16px;
        line-height: 31px;
        text-transform: capitalize;

        &.bg-color-1 {
            background-color: #fdadbe;
        }

        &.bg-color-2 {
            background-color: #9f9ad0;
        }

        &.bg-color-3 {
            background-color: #e8c2a2;
        }

        &.bg-color-4 {
            background-color: #00c3e6;
        }

        &.bg-color-5 {
            background-color: #fec358;
        }

        &.bg-color-6 {
            background-color: #f8abaa;
        }

        &.bg-color-7 {
            background-color: #9ab9c3;
        }
    }

    .add-user {
        .project-img {
            border: 2px solid $white-color;
            margin-right: -7px;
            object-fit: cover;
        }

        span {
            border: 2px solid $white-color;
            border-radius: 50%;
            text-align: center;
            font-size: 13px;
            display: inline-block;
            background: $dark-charcoal-color;
            color: $white-color;
            padding: 7px 6px;
            min-width: 44px;
            height: 44px;
        }
    }
}

.position-class {
    position: relative;
}

.project-tab {
    position: absolute;
    right: -2px;
    top: 24px;
    box-shadow: none;

    .nav-link {
        font-size: 17px;
        padding: 6px 10px;
    }
}

.pause-play-button {
    font-size: 10px;
    width: 24px;
    height: 24px;
    background: $blue-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: $white-color;
    border: none;
    padding: 6px;
}

.attachment-files {
    .attachmentimg {
        .documnet-attachment {
            &.close-btn {
                .btn-close-btn-icon {
                    right: -9px;
                    top: -10px;
                    height: 20px;
                    width: 20px;
                    font-size: 9px;
                }
            }
        }
    }
}

.attachmentimg {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    gap: 10px;

    .documnet-attachment {
        border: 1px solid $bright-gray-border-color;
        background: $white-color;
        border-radius: 5px;
        text-align: center;
        padding: 8px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &.close-btn {
            position: relative;

            .btn-close-btn-icon {
                position: absolute;
                right: -7px;
                top: -10px;
                border: 1px solid #dcd5d5;
                border-radius: 50px;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 25px;
                width: 25px;
                font-size: 13px;
                background: #ffffff;
                color: #dcd5d5;
            }
        }

        &.plus-open {
            justify-content: center;
            border-color: $bright-gray-border-color;
            font-size: 26px;
            color: $timberwolf-color;
            position: relative;

            input {
                bottom: 0;
                position: absolute;
                height: 100%;
                width: 100%;
                /* z-index: 999;*/
                top: 0;
                left: 0;
                right: 0;
                padding: 10px;
                cursor: pointer;
                font-size: 0;
                opacity: 0;
            }
        }
    }

    .documnet-file {
        display: inline-block;
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
    }

    .documnet-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        color: $bs-body-color;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
    }
}

.dashboard-area {
    //   padding: 0 1.375rem;
    margin-top: 106px;
}

.vault-tab {
    .table-deteil {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 480px;
    }
}

.share-download-btn {
    &.archive-icon {
        position: absolute;
        right: 100px;
    }
}

/*alert message popup*/
#react-confirm-alert {
    position: relative;
    z-index: 99999;

    .react-confirm-alert-overlay {
        background-color: rgba(0, 0, 0, 0.4);
        font-size: 20px;
        color: #1c212d;

        .react-confirm-alert-body {
            text-align: center;
        }

        .react-confirm-alert-button-group {
            justify-content: center;

            button {
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                text-align: center;
                border: none;
                min-width: 100px;
                height: 42px;

                &:first-child {
                    background-color: #00bc8b;
                }

                &:last-child {
                    background-color: rgba(128, 142, 167, 0.2);
                    color: #000000;
                }
            }
        }
    }
}
/*alert message popup*/

.project-add-icon {
    .project-btn.btn.btn-primary {
        color: $white-color;
        background: $blue-bg;
        border-color: $blue-bg;
        border-radius: 50px;
        font-size: 18px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
    }

    &.open-file {
        position: relative;

        input {
            bottom: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            /*    z-index: 999;*/
            top: 0;
            left: 0;
            right: 0;
            padding: 10px;
            cursor: pointer;
            font-size: 0;
            opacity: 0;
        }
    }
}

.upcoming-birthday-events-main {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;
}

.upcoming-birthday-events-main {
    .card {
        max-width: 280px;
        flex-direction: inherit;
        align-items: center;
        padding: 0 10px;
        border: 1px solid #345bf8;
    }

    .pastel-orange.card {
        border: 1px solid #ff9e01 !important;
    }

    img {
        height: 60px;
        object-fit: cover;
        width: 60px;
        border-radius: 50%;
    }

    .card-title.h5 {
        font-size: 18px;
    }

    .card-title p {
        color: #345bf8;
    }
}

.label-badges {
    padding: 6px 13px;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    background: #ffffff;
    border-radius: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;

    &.in-testing,
    &.running {
        color: #ff9e01;
    }

    &.in-progress {
        color: #345bf8;
    }

    &.not-started {
        color: #808ea7;
    }

    &.on-hold {
        color: #f04141;
    }

    &.closed,
    &.completed {
        color: #00bc8b;
    }

    &.in-queue {
        color: #154bbe;
    }

    &.stop {
        color: #f04141;
    }
}

.project-cards {
    .slick-list {
        padding: 0 !important;
    }

    .slick-slide {
        max-width: 450px;
        width: 100%;
    }

    .slick-slide > div {
        margin-right: 20px;
    }

    .card-body {
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 330px;
        position: relative;
    }

    .card-body-inner {
        position: absolute;
        bottom: 80px;
        width: 90%;
    }

    .task-label {
        background: rgba(255, 255, 255, 0.15);
        padding: 7px 10px;
        border-radius: 10px;
        margin-left: 10px;
        white-space: nowrap;

        i {
            margin-right: 5px;
        }

        span {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #ffffff;
            text-transform: capitalize;
        }
    }

    .progress {
        height: 10px;

        .progress-bar {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }

    .ProgressBar-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }

    .ProgressBar-percentage {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
    }

    .card {
        border-radius: 20px;
        color: $white-color;
        border: none;
        height: 100%;

        .card-img-center {
            background: #ffffff;
            padding: 1px;
            margin-right: 20px;
            font-size: 26px;
            color: #1c212d;
            text-align: center;
            line-height: 75px;
            font-weight: 600;
            text-transform: uppercase;
        }

        .card-title {
            text-transform: capitalize;
            font-weight: 700;
            font-size: 22px;
            line-height: 26px;
            color: #ffffff;
            margin-bottom: 0;
            overflow: hidden;
            max-width: 185px;
        }

        .btn-ellipsis-btn {
            line-height: initial;
            color: #fff;
            font-size: 18px;
            border: none;
            box-shadow: none;

            &:hover,
            &:active {
                border: none;
                box-shadow: none;
            }

            &.dropdown-toggle:empty::after {
                content: "\f141";
                border: none;
                font-family: "Font Awesome 6 Pro";
                font-size: 22px;
            }
        }
        // .dropdown-toggle::after{
        //   display: none;
        // }
        .dropdown-item {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;

            &:active {
                background-color: transparent;
            }
        }

        .task-date {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-transform: capitalize;
        }

        .users {
            position: relative;
            padding-right: 11px;
            margin-left: 24px;

            .project-img {
                margin-right: -13px;
            }

            .add-user {
                position: absolute;
                right: -2px;
                top: 0px;

                span {
                    min-width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 1.3;
                    font-weight: 600;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0, 0, 0, 0.4);
                    color: $white-color;
                    padding: 7px 6px;
                }
            }
        }
    }

    .orange-peel-card {
        .project-img {
            border-color: #ff9e01;
        }

        .users {
            .add-user {
                span {
                    border-color: #ff9e01;
                }
            }
        }
    }

    .ultramarine-blue-card {
        .project-img {
            border-color: #345bf8;
        }

        .users {
            .add-user {
                span {
                    border-color: #345bf8;
                }
            }
        }
    }

    .caribbean-green-card {
        .project-img {
            border-color: #00bc8b;
        }

        .users {
            .add-user {
                span {
                    border-color: #00bc8b;
                }
            }
        }
    }

    .carmine-pink-card {
        .project-img {
            border-color: #f04141;
        }

        .users {
            .add-user {
                span {
                    border-color: #f04141;
                }
            }
        }
    }

    .project-cards-labels {
        margin-top: 10px;

        label {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            background: rgba(255, 255, 255, 0.15);
            border-radius: 5px;
            padding: 3px 8px;
            margin-right: 5px;
            text-transform: uppercase;
            margin-bottom: 5px;
            text-align: center;
            white-space: nowrap;
        }
    }
}

/*cards*/
.card-square-bg {
    background: url("../Images/project-card-bg-1.png") no-repeat right top;
}

.card-round-bg {
    background: url("../Images/project-card-bg-2.png") no-repeat right top;
}

.eerie-black {
    background-color: $eerie-black-bg;
}

.ultramarine-blue {
    background-color: #345bf8;
}

.carmine-pink {
    background-color: #f04141;
}

.orange-peel {
    background-color: #ff9e01;
}

.caribbean-green {
    background-color: #00bc8b;
}

.square-bg-img {
    background: url("../Images/card-bg-1.svg") no-repeat 16px 0px;
}

.round-bg-img {
    background: url("../Images/card-bg-2.svg") no-repeat bottom right 16px;
}

.hexagon-bg-img {
    background: url("../Images/card-bg-3.svg") no-repeat top left;
}

.blue-crayola {
    //   background-color: $blue-bg;
    background: rgba(52, 91, 248, 0.1);
}

.go-green {
    //   background-color: $go-green-bg;
    background: rgba(0, 188, 139, 0.1);
}

.pastel-orange {
    //   background-color: $pastel-orange-bg;
    background: rgba(255, 158, 1, 0.1);
}

.caribbean-green-box {
    background: rgba(0, 188, 139, 0.1);
}

/*cards*/

.status-icon {
    /*cursor: pointer;*/
    margin-left: 10px;

    i {
        font-size: 22px;

        &.fa-circle-check {
            color: #28a745;
        }

        &.fa-circle-xmark {
            color: #db562c;
        }

        &.fa-spinner-third {
            color: #1a72f2;
        }

        &.fa-clock {
            color: #db562c;
        }

        &.waiting-icon {
            color: #f5b708;
        }

        &.fa-user-gear {
            color: #1a72f2;
        }
    }
}

// File-modal
.allFile_iframe {
    width: 100%;
    height: calc(100vh - 200px);

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    video {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
}

/*form css*/

/*form css for multiple select lable*/
.form-floating {
    &.multipleselect-form-floating {
        .css-1v99tuv {
            position: absolute;
            top: 0;
            left: 0;
            color: #212529;
            line-height: 18px;
            font-size: 14px;
            opacity: 1;
            transform: scale(0.85) translateY(-0.65rem) translateX(0.15rem);
            background: #ffffff;
            display: inline-block;
            width: unset;
            padding: 0 8px;
        }

        > div {
            > button {
                height: 44px;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                appearance: none;
                border-radius: 0.625rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                box-shadow: none;
                display: flex;
                align-items: center;
                justify-content: right;
            }

            span ~ span {
                position: relative;
                display: block;
                right: 5px;
                width: 100%;
                text-align: right;

                svg {
                    background: url("../Images/down-arrow.svg") no-repeat right center;

                    path {
                        display: none;
                    }
                }
            }

            > div {
                width: 100%;
                border: 1px solid rgba(0, 0, 0, 0.1);
            }
        }

        &.filter-form {
            > div {
                > div {
                    .css-1pcexqc-container .css-ik6y5r {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

/*form css*/

/*right-modal*/
.fade {
    &.right-modal {
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1055;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        outline: 0;
        max-width: 50%;
        margin: 0 0 0 auto;
        min-height: 100vh;

        .modal-dialog {
            width: 100%;
            max-width: 100%;
            margin: 0 0 0 auto;
            min-height: 100vh;
            display: flex;
        }

        .modal-content {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;

            .modal-header {
                .modal-title {
                    font-weight: 600;
                    font-size: 22px;
                    line-height: 33px;
                    text-transform: uppercase;
                    color: #000000;
                }

                .btn-close {
                    background: url("../Images/close-icon.svg") no-repeat right 5px center;
                    opacity: 1;
                    margin-left: unset;
                }
            }

            .modal-footer {
                padding: 1rem;
                justify-content: end;
                flex-direction: row-reverse;
            }
        }
    }
}
/*right-modal*/
.task-attachment-title {
    text-transform: capitalize;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #1c1c1c;
}

.attachment-files {
    .attachmentimg {
        grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));

        .documnet-attachment {
            &.plus-open {
                font-size: 22px;
            }
        }
    }

    .documnet-attachment {
        height: 68px;
        padding: 0;
    }

    .documnet-img {
        border-radius: 6px;
    }
}

.down-arrow-icon {
    background: url("../Images/down-arrow.svg") no-repeat right 5px center;
}

.calendar-icon {
    // background: url("../Images/calendar-icon.svg") no-repeat right 15px center;
    background: url("../Icons/calendar-icon.svg") no-repeat right 15px center;
    background-size: 19px;
}

.task-detail-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inner-header {
    background: #f3f5fd;
    border-radius: 5px;
    padding: 10px 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 10px;
}

.tab-inner-box {
    padding: 0 15px;

    .tab-inner-box-left {
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #1c1c1c;
    }

    .tab-inner-box-right {
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #000000;
    }
}

.comment-col {
    .user-comment-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
    }

    .comment-time {
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        color: #000000;
    }

    .comments {
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        margin-top: 5px;
    }

    .comment-col-button {
        margin-top: 5px;
    }

    .comment-col-button {
        .btn-edit,
        .btn-delete {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #000000;
        }
    }

    .comment-profile-img {
        border-radius: 50%;
        border: 1px solid #000000;
    }
}

.history-block {
    .comment-col {
        .comments {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.spinner-border {
    vertical-align: middle;
    margin-right: 10px;
    --bs-spinner-width: 1.2rem;
    --bs-spinner-height: 1.2rem;
    --bs-spinner-border-width: 0.15em;
}

.bg-gray {
    background-color: #f3f5fd;
}

.tab-inner-box-right {
    .project-assign-salect {
        min-width: 290px;
    }
}

.comment-block {
    .comment-field {
        position: relative;

        .form-control {
            padding-right: 75px;
        }

        .two-bottom-buttons {
            position: absolute;
            bottom: 3px;
            right: 15px;

            .documnet-attachment {
                &.plus-open {
                    position: relative;

                    input {
                        bottom: 0;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        /* z-index: 999;*/
                        top: 0;
                        left: 0;
                        right: 0;
                        cursor: pointer;
                        font-size: 0;
                        opacity: 0;
                    }
                }
            }

            button {
                &:active {
                    border: none;
                }
            }
        }
    }
}

// .progress {
//   --bs-progress-bar-color: yellow;
//   --bs-progress-bar-bg: white;
//   --bs-progress-bg: #ffffff30;
//   --bs-progress-height: 0.3rem;
// }

.project-cards {
    .progress {
        --bs-progress-bar-color: yellow;
        --bs-progress-bar-bg: white;
        --bs-progress-bg: #ffffff30;
        --bs-progress-height: 0.3rem;
    }
}

a.remove-hover:hover {
    color: unset !important;
}

.task-list span {
    min-width: 30px !important;
    height: 30px !important;
    line-height: 1.2;
}

.btn-close-subpoint-icon {
    position: absolute;
    right: -7px;
    top: -10px;
    border: 1px solid #dcd5d5;
    border-radius: 50px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    font-size: 13px;
    background: #ffffff;
    color: #dcd5d5;

    &:hover {
        background: #ffffff;
        color: #dcd5d5;
        border: 1px solid #dcd5d5 !important;
    }
    // .btn-check:checked+.btn,
    // .btn.active,
    // .btn.show,
    // .btn:first-child:active,
    //  :not(.btn-check)+.btn:active{
    //   background: #ffffff !important;
    //   color: #dcd5d5 !important;
    //   border: 1px solid #dcd5d5 !important;
    //  }
}

.task-detail-status-btn button,
.task-detail-status-btn button:hover {
    border: 1px solid;
}

.inner-page-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #1c212d;
}

/*custom-tabs*/
.custom-tabs {
    background: #ffffff;
    border-radius: 10px;
    padding: 5px;

    .nav-link {
        color: #9da1a7;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        padding: 7px 19px;
        position: relative;

        &.active {
            background: #f3f6f9;
            border-radius: 5px;
            color: #000000;

            &:after {
                content: "";
                border-right: 1px solid #f3f6f9;
            }
        }

        &:after {
            content: "";
            border-right: 1px solid #f3f6f9;
            position: absolute;
            right: -2px;
            height: 30px;
            top: 2px;
        }

        &:last-child {
            &.active {
                &:after {
                    content: "";
                    display: none;
                }
            }
        }
    }

    .nav-item {
        &:last-child {
            .nav-link {
                &::after {
                    border-right: none;
                }
            }
        }
    }

    &.custom-tabs-grey {
        background: #f3f6f9;

        .nav-link {
            &.active {
                background: #ffffff;
                box-shadow: 0px 0px 10px rgba(128, 142, 167, 0.15);

                &:after {
                    content: "";
                    border-right: 1px solid #d1d2d5;
                }
            }

            &:after {
                content: "";
                border-right: 1px solid #d1d2d5;
                position: absolute;
                right: -2px;
                height: 30px;
                top: 2px;
            }

            &:last-child {
                &.active {
                    &:after {
                        content: "";
                        display: none;
                    }
                }
            }
        }

        .nav-item {
            &:last-child {
                .nav-link {
                    &::after {
                        border-right: none;
                    }
                }
            }
        }
    }
}
/*custom-tabs*/
.inner-page-top-col {
    margin-bottom: 20px;
}

.projects-cards {
    .card-white-bg {
        color: #000000;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    }

    .card {
        .project-card-content-right {
            // width: calc(100% - 25px);
            width: 92%;
            display: table;
        }

        .projects-cards-dropdown {
            position: absolute;
            right: 20px;
            top: 20px;
        }

        .card-title {
            color: #000000;
        }

        .task-date {
            color: #9da1a7;
        }

        .project-cards-labels {
            label {
                background: rgba(0, 0, 0, 0.05);
                color: #000000;
            }
        }

        .task-label {
            background: rgba(0, 0, 0, 0.05);

            span {
                color: #000000;
            }
        }

        .progress {
            background: rgba(0, 0, 0, 0.15);
        }

        .progress-bar {
            background: #00bc8b;
        }

        .btn-ellipsis-btn {
            color: #000000;
        }

        .card-img-center {
            background: #f3f5fd;
            font-weight: 600;
            font-size: 26px;
            line-height: 70px;
            text-transform: capitalize;
            text-align: center;
            color: #1c212d;
        }
    }

    .label-badges {
        background: rgba(0, 0, 0, 0.05);
    }
}

.pro-details-left-header {
    &.projects-cards {
        .card {
            .project-details-labels {
                label {
                    background: rgba(52, 91, 248, 0.05);
                    color: #345bf8;
                }
            }
        }
    }
}

.general-tab {
    .cards {
        .card-body {
            padding: 20px;

            .card-title {
                margin-bottom: 20px;
            }

            .project-details {
                display: inline-block;

                .users {
                    justify-content: flex-end;
                    position: relative;
                    grid-gap: 8px;
                }

                .project-img {
                    color: #1c212d;
                    background: #f3f5fd;
                }

                .add-user {
                    position: relative;
                    // right: -7px;
                    // top: 0px;
                    span {
                        min-width: 40px !important;
                        height: 40px !important;
                        background: rgba(0, 0, 0, 0.4);
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }

            .card-header-top {
                margin-bottom: 10px;

                .project-title {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 30px;
                }
            }
        }
    }

    .pro-details-description {
        margin-top: 20px;

        .pro-details-des-header {
            margin-bottom: 20px;
        }

        .about-project-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #000000;
        }

        label {
            background: rgba(240, 65, 65, 0.15);
            border-radius: 5px;
            color: #f04141;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding: 5px 10px;
        }

        .project-description {
            font-weight: 400;
            font-size: 20px;
            line-height: 25px;
            color: #1c212d;
            margin-bottom: 40px;
        }
    }

    .pro-details-description-bottom {
        span {
            border-right: 1px solid #d1d2d5;
            margin-right: 20px;
            padding-right: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: #000000;
            text-transform: capitalize;

            &:last-child {
                margin-right: 0;
                padding-right: 0;
                border-right: 0;
            }

            svg {
                width: 19px;
                height: 19px;
            }
        }
    }

    .activities-title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #000000;
        margin-bottom: 20px;
        border-top: 1px solid #dce0e4;
        margin-top: 20px;
        padding-top: 20px;
    }
}

.pro-details-tab-content {
    margin-top: 20px;
}

.timeline-items {
    .timeline-item {
        position: relative;

        .timeline-img {
            position: relative;
            z-index: 1;
        }

        .timeline-chat {
            padding-bottom: 30px;
            padding-left: 10px;
            padding-top: 5px;
            width: 100%;

            &::before {
                content: "";
                border-left: 1px dashed #c8cbd0;
                position: absolute;
                display: inline-block;
                height: 100%;
                left: 20px;
            }

            .chat-name {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: #1c212d;
            }

            .time-day {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: rgba(28, 33, 45, 0.5);
                margin-top: auto;
                margin-bottom: auto;
            }

            .timeline-commente {
                background: #f3f6f9;
                border-radius: 20px;
                padding: 15px;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #1c212d;
            }

            .timeline-commente-share {
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                margin-top: 12px;
                margin-bottom: 15px;

                span {
                    color: rgba(28, 33, 45, 0.5);
                }

                .commente-name {
                    color: #345bf8;
                    margin-left: 5px;
                    text-transform: capitalize;
                }
            }
        }

        &:last-child {
            .timeline-chat {
                padding-bottom: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

.timeline-files-share {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    grid-column-gap: 5px;
    grid-row-gap: 5px;

    .timeline-file-share {
        position: relative;
    }

    .file-share-img {
        border-radius: 10px;
        width: 100%;
    }

    .file-type {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25));
        position: absolute;
        top: 50%;
        height: 60px;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        border-radius: 10px;
        text-align: center;

        .file-type-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            left: 0;
        }
    }
}

.task-table-tab {
    table.table {
        .table-inner-section {
            .leave-reason,
            .project-description {
                font-weight: 600;
                font-size: 20px;
                line-height: 25px;
                color: #1c212d;
            }
        }

        .project-details {
            display: inline-block;

            .users {
                justify-content: flex-end;
                position: relative;
                flex-wrap: wrap;

                .project-img {
                    font-size: 15px;
                    line-height: 30px;
                    background-color: #f3f5fd;
                }

                .add-user.task-list {
                    position: absolute;
                    right: -6px;
                    top: 6px;

                    span {
                        width: 34px;
                        height: 34px !important;
                        font-size: 14px;
                        background: rgba(0, 0, 0, 0.4);
                    }
                }
            }
        }
    }

    .table-header-col {
        padding: 0 20px;
    }

    .total-time {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #1c212d;
    }

    .table-text-common {
        svg {
            width: auto;
            height: 18px;
        }
    }
}

.setting-tab {
    .form-floating {
        textarea {
            height: 100px;
        }
    }
}

.modal-class {
    .form-floating {
        textarea {
            height: 100px;
        }
    }
}

.form-group {
    &.checkbox-class {
        display: block;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 18px;
        color: #000000;

        label {
            display: inline-flex;
            align-items: center;
            cursor: pointer;

            &:before {
                content: "";
                width: 24px;
                height: 24px;
                background: #ffffff;
                border: 2px solid #000000;
                display: inline-block;
                margin-right: 10px;
                border-radius: 5px;
            }
        }

        input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
        }

        input[type="checkbox"]:checked ~ label:before {
            background: #00bc8b no-repeat center;
            background-size: 16px;
            background-image: url("../Icons/check-icon.svg");
            border-color: #00bc8b;
        }
    }
}

.files-tab {
    .files-tab-items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(314px, 1fr));
        grid-column-gap: 20px;
        grid-row-gap: 20px;
    }

    .files-tab-item-img {
        height: 240px;
        display: flex;
        align-content: center;
        justify-content: center;
        width: 100%;
    }

    .files-img-type {
        width: 100%;
        text-align: center;
    }

    .files-img {
        height: 240px;
        width: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        aspect-ratio: 16/9;
    }

    .files-tab-bottom-left {
        width: calc(100% - 40px);
    }

    .files-tab-bottom-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #1c212d;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .files-tab-item {
        background: #fff;
        border-radius: 20px;
    }

    .files-tab-item-bottom {
        display: flex;
        padding: 15px 20px;
    }

    .files-tab-bottom-right {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
    }

    .files-bottom-size-data {
        display: flex;
        margin-top: 5px;

        .files-size-bottom {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #1c212d;
        }

        .files-upload-data-bottom {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: rgba(28, 33, 45, 0.5);
            border-left: 1px solid rgba(28, 33, 45, 0.5);
            padding-left: 10px;
            margin-left: 10px;
        }
    }
}

.vault-tab {
    table.table {
        &.table-inner-section {
            thead tr th {
                font-weight: 700;
                font-size: 20px;
                line-height: 25px;
                color: #1c212d;
                // padding-bottom: 20px;
                height: 56px;
            }

            .project-description {
                font-size: 20px;
                line-height: 25px;

                &.username {
                    // padding-left: 15px;
                }
            }
        }

        tr {
            th {
                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 25px;
                }

                .username {
                    padding-left: 35px;
                }
            }

            td {
                &:first-child {
                    padding-left: 25px;
                }

                &:last-child {
                    padding-right: 25px;
                }
            }
        }

        &.dataTable {
            tbody.table-section-col {
                background-color: transparent;

                tr {
                    td {
                        background: #ffffff;
                    }

                    &:first-child {
                        td {
                            &:first-child {
                                border-top-left-radius: 20px;
                            }

                            &:last-child {
                                border-top-right-radius: 20px;
                            }
                        }
                    }

                    &:last-child {
                        td {
                            &:first-child {
                                border-bottom-left-radius: 20px;
                            }

                            &:last-child {
                                border-bottom-right-radius: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    tbody.table-section-col {
        tr {
            &:first-child {
                td {
                    &:first-child {
                        border-top-left-radius: 20px;
                    }

                    &:last-child {
                        border-top-right-radius: 20px;
                    }
                }
            }

            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: 20px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 20px;
                    }
                }
            }
        }
    }

    .sr-no {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: rgba(28, 33, 45, 0.5);
    }

    .table-text-common,
    .vault-description {
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #1c212d;
        overflow: hidden;
    }

    .vault-description {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 350px;
    }
}

.password-dots i {
    color: #8a8c92;
    margin: 0 2px;
    font-size: 21px;
    line-height: 0;
}

/*new css for calendar*/
.calendar-col {
    .calendar-top-col {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .calendar-title-main {
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        color: #000000;
        margin: 0 15px;
    }

    .icon-prev,
    .icon-next {
        width: 30px;
        text-align: center;
        cursor: pointer;
    }

    .days {
        padding: 10px 0 10px;

        .col {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #767981;
            text-transform: capitalize;
            text-align: center;
        }
    }

    .col {
        &.cell {
            .number {
                width: 100%;
                height: 47px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #ffffff;
                border-radius: 50%;
                color: #1c212d;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                cursor: pointer;
            }

            &.today {
                .number {
                    background: #345bf8;
                    color: #ffffff;
                    font-weight: 600;
                }
            }

            &.selected {
                .number {
                    background: #f3f6f9;
                    color: #1c212d;
                }
            }
        }
    }
}

.board-tab {
    // .board-items {
    //   display: grid;
    //   grid-template-columns: repeat(auto-fill, minmax(314px, 1fr));
    //   grid-column-gap: 20px;
    //   grid-row-gap: 20px;
    // }
    .board-items {
        display: flex;
        padding-inline: 2rem;
        padding: 0;
        overflow-x: auto;
        scrollbar-width: thin;
    }

    .board-item {
        display: flex;
        flex-direction: column;
        min-width: 362px;
        word-wrap: break-word;
        background-clip: border-box;
        max-width: 362px;
        margin-right: 21px;
    }

    .task-header {
        font-weight: 700;
        font-size: 20px;
        line-height: 25px;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .task-number {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            min-width: 30px;
            height: 30px;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            padding: 0 6px;
        }

        &.not-started-col {
            color: #808ea7;

            .task-number {
                background: #808ea7;
                color: #f3f6f9;
            }
        }

        &.in-process-col {
            color: #345bf8;

            .task-number {
                background: #345bf8;
                color: #f3f6f9;
            }
        }

        &.in-testing-col {
            color: #ff9e01;

            .task-number {
                background: #ff9e01;
                color: #f3f6f9;
            }
        }

        &.closed-col {
            color: #00bc8b;

            .task-number {
                background: #00bc8b;
                color: #f3f6f9;
            }
        }

        &.onhold-col {
            color: #8484ff;

            .task-number {
                background: #8484ff;
                color: #f3f6f9;
            }
        }
    }

    .board-hight {
        height: calc(100vh - 365px);
        overflow-x: hidden;
        scrollbar-width: none;
    }

    .draggable-box {
        width: 100%;
        cursor: grab;
        background: #f3f6f9;
        border-radius: 20px;
        min-height: 5em;
        padding: 20px;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .draggable-box-project-tag {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
        margin-right: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgba(28, 33, 45, 0.5);
        margin-bottom: 0;
    }

    .draggable-box-board-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-top: 8px;
    }

    .draggable-box-description {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgba(28, 33, 45, 0.5);
        margin-top: 5px;
    }

    .draggable-box-img {
        margin-top: 15px;

        img {
            width: 100%;
            height: 90px;
            object-fit: cover;
        }
    }

    .day-and-date {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
    }

    .draggable-box-bottom {
        margin-top: 15px;
        align-items: center;
    }

    .users-imgs-col {
        .users-imgs {
            position: relative;

            .user-img {
                border: 2px solid #f3f6f9;
                margin-right: -7px;
                object-fit: cover;
                font-weight: 600;
                font-size: 16px;
                line-height: 31px;
                text-transform: capitalize;
                border-radius: 50%;
            }

            .add-user {
                position: absolute;
                right: -7px;
                top: 0px;

                span {
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    background: rgba(0, 0, 0, 0.4);
                    display: inline-block;
                    border: 2px solid #ffffff;
                    border-radius: 50%;
                    color: #ffffff;
                    text-align: center;
                    line-height: 24px;
                }
            }
        }
    }
}

/*modal file*/
.file-view-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    width: 100%;
    height: 100%;
    overflow: auto;
    outline: 0;
    background-color: $white-color;
    transition: opacity 0.15s linear;
}

.file-view-popup.view {
    display: block !important;
}

.file-view-popup .file-view-popup-dialog {
    margin: 0;
    max-width: 100%;
}

.file-view-popup .file-view-popup-dialog .file-view-popup-dialog-header {
    padding: 10px;
    border-bottom: 1px solid rgba(32, 40, 66, 0.2);
    font-size: 20px;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-header
.close-button {
    background-color: transparent;
    background: transparent;
    border: transparent;
    float: right;
}

.file-view-popup .file-view-popup-dialog .file-view-popup-dialog-body {
    padding: 1rem;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
a {
    text-align: center;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
.file-display-area {
    text-align: center;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
.file-display-area
img {
    max-width: 100%;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
.html-display-area {
    padding: 0px 100px;
}

.file-view-popup .file-view-popup-dialog .nextpriv-icon {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file-view-popup .file-view-popup-dialog .file-name {
    text-align: center;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
iframe,
.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
video,
.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
audio {
    width: 100%;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
a
div.icon-display {
    padding: 10px;
    background: $white-color;
    color: #ff8e8e;
    font-size: 150px;
}

.file-view-popup
.file-view-popup-dialog
.file-view-popup-dialog-body
.file-view
a
div.message-display {
    padding: 10px;
    background: $light-silver-color;
    color: $black-color;
    font-size: 18px;
}

/*common-page title*/
.page-title-left {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    color: #000000;
}

.common-header-page {
    margin-bottom: 10px;

    .inner-search-bar {
        margin-right: 20px;
    }
}

.task-page {
    .table-header-col {
        margin-bottom: 15px;
    }

    .project-title {
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        color: #1c212d;
    }

    .recent-task-section {
        margin-bottom: 25px;
    }

    .cards {
        .card-title {
            margin-bottom: 16px;
            font-weight: 700;
            font-size: 26px;
            line-height: 33px;
            min-width: 56px;
            min-height: 56px;
            width: 56px;
            height: 56px;
        }

        .card-body {
            padding: 15px 15px;
        }

        .card-text {
            font-weight: 400;
            font-size: 15.6569px;
            line-height: 18px;

            span {
                font-weight: 700;
                font-size: 22px;
                line-height: 28px;
            }
        }
    }

    .caribbean-green-box {
        .card-title {
            background: #00bc8b;
        }
    }
}

.task-table-tab {
    .table-section-col {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    }
}

.filter-col {
    .filter-col .btn-primary {
        width: auto;
        font-size: 0;
        padding: 0;
        min-width: auto;
        height: auto;
        line-height: unset;
        border-radius: 0;
        background: transparent;
        border: transparent;
    }
}

.setting-page {
    margin-left: -35px;

    &:before {
        content: "";
        width: 35px;
        height: calc(100vh - 128px);
        display: inline-block;
        background: #ffffff;
        position: absolute;
    }

    .table-section-col {
        padding-left: 35px;

        &.setting-page-tab {
            height: calc(100vh - 128px);
        }
    }

    .nav-pills {
        .nav-link {
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: #d1d2d5;
            padding: 11px 20px 11px 11px;
            border-radius: 10px;

            &.active {
                background: rgba(0, 188, 139, 0.06);
                color: #00bc8b;
                font-weight: 600;
                outline: none;
            }
        }

        .show > .nav-link {
            background: rgba(0, 188, 139, 0.06);
            color: #00bc8b;
            outline: none;
        }
    }

    table.table {
        tr th {
            .username {
                padding-left: 0px;
            }
        }
    }

    .setting-left-sidebar-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #000000;
        padding-left: 12px;
        padding-bottom: 10px;
        padding-top: 5px;
    }
}

.custom-modal {
    .custom-modal-body {
        position: absolute;
        right: 110px;
        top: 83px;
        max-width: 400px;
        background: #fff;
        z-index: 1;
        padding: 20px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.02);
    }
}

.from-bottom-margin {
    margin-bottom: 20px;
}

.profile-page {
    .profile-section-left {
        .profile-top-col {
            position: relative;
            margin-bottom: 30px;
        }

        .profile-title {
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
            color: #000000;
            text-align: center;
        }

        .profile-edit-icon {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        .user-profile-col {
            .user-profile-img {
                background-color: #f3f5fd;
                line-height: 105px;
                font-size: 55px;
                display: inline-block;
                color: #1c212d;
                font-weight: 600;
                text-transform: uppercase;
            }

            .user-profile-name {
                font-weight: 600;
                font-size: 32px;
                line-height: 40px;
                color: #000000;
                text-transform: capitalize;
                margin-top: 10px;
            }

            .user-profile-department {
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                color: #000000;
                opacity: 0.5;
                text-transform: capitalize;
                margin-top: 2px;
            }
        }

        .profile-bar-col {
            .profile-bar-status-col {
                .profile-bar-status {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 30px;
                    color: #000000;
                }

                .profile-bar-percentage {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #000000;
                }
            }

            .profile-bar {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                gap: 6px;
                margin-top: 10px;

                span {
                    height: 14px;
                    display: inline-block;
                    width: 100%;
                    background: #f2f2f2;

                    &:first-child {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }

                    &:last-child {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    }
                }

                .active {
                    background: #345bf8;
                }
            }
        }

        .profile-contact {
            margin-top: 19px;

            .profile-contact-title {
                font-weight: 600;
                font-size: 24px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 15px;
            }

            .contact-item {
                background: #f3f6f9;
                border-radius: 5px;
                padding: 10px 15px;
                margin-bottom: 10px;
                word-break: break-word;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .contact-item-icon {
                background: #fff;
                min-width: 53px;
                min-height: 53px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 53px;
                height: 53px;
                padding: 10px;
            }

            .contact-item-detail {
                padding-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            .contact-item-top {
                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
                opacity: 0.5;
                text-transform: capitalize;
            }

            .contact-item-bottom {
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
            }
        }
    }

    .profile-common-header {
        background: #f3f6f9;
        border-radius: 5px;
        padding: 14px 15px;

        .profile-common-header-title {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            color: #000000;
        }

        &.qualifications {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &.add-button {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    .empl-information {
        display: grid;
        // grid-template-columns: repeat(5, 1fr);
        grid-template-columns: repeat(4, 1fr) auto;
        gap: 30px;
        margin-top: 30px;
        margin-bottom: 30px;
        padding-left: 15px;

        .empl-infor-top {
            font-weight: 400;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
            opacity: 0.5;
            text-transform: capitalize;
        }

        .empl-infor-bottom {
            font-weight: 600;
            font-size: 18px;
            line-height: 23px;
            color: #000000;
            text-transform: capitalize;
        }

        .empl-infor-label {
            color: #000000;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 3px 8px;
            margin-top: 5px;
            margin-right: 5px;
            display: inline-block;
        }
    }

    .vault-tab {
        table.table.table-inner-section {
            thead {
                tr {
                    th {
                        font-weight: 600;
                        padding-bottom: 0;
                        background: #f2f2f2;

                        &.padding-top-bottom {
                            height: 40px;
                            padding-top: 10px;
                            padding-bottom: 10px;
                        }

                        &:first-child {
                            padding-left: 15px;
                        }

                        &:last-child {
                            padding-right: 15px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        // padding-bottom: 0;
                        &.padding-top-bottom {
                            height: 56px;
                        }

                        &:first-child {
                            padding-left: 15px;
                        }

                        &:last-child {
                            padding-right: 15px;
                        }

                        .table-text-common {
                            color: #7e7e7e;

                            &.credentials {
                                color: #345bf8;
                            }
                        }

                        .leave-reason {
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                        }
                        // .leave-request-type {
                        //   font-weight: 700;
                        //   font-size: 18px;
                        //   line-height: 43px;
                        //   color: #ffffff;
                        //   width: 45px;
                        //   height: 45px;
                        //   display: inline-block;
                        //   text-transform: uppercase;
                        //   text-align: center;
                        //   &.lwp {
                        //     background: #ff9e01;
                        //   }
                        //   &.cl {
                        //     background: #345bf8;
                        //   }
                        // }
                        .apply-date {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 24px;
                            color: #000000;
                        }
                    }
                }
            }
        }
    }

    .cards {
        padding-left: 0;
        padding-right: 0;

        .card-body {
            padding: 15px 15px;
        }

        .card-title {
            margin-bottom: 22px;
        }
    }

    .profile-documents-tabs {
        padding-top: 10px;

        .documents-page-tab {
            padding: 0;

            .nav-pills {
                .nav-link {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 23px;
                    color: #d1d2d5;
                    padding: 11px 20px 11px 11px;
                    border-radius: 5px;

                    &.active {
                        background: rgba(0, 188, 139, 0.06);
                        color: #00bc8b;
                        font-weight: 600;
                    }
                }

                .show > .nav-link {
                    background: rgba(0, 188, 139, 0.06);
                    color: #00bc8b;
                }
            }
        }

        .tab-content {
            background: #f3f6f9;
            border-radius: 5px;
            padding: 20px;
            height: calc(100vh - 290px);
            overflow-y: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            overflow-y: scroll;
            scrollbar-width: none;
        }

        .profile-document-lists {
            display: grid;
            // grid-template-columns: repeat(auto-fill, minmax(313px, 1fr));
            grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
            grid-column-gap: 20px;
            grid-row-gap: 20px;

            .profile-document-list {
                background: #fff;
                border-radius: 20px;

                .profile-document-file {
                    height: 140px;
                    display: flex;
                    align-content: center;
                    justify-content: center;
                    width: 100%;
                }

                .profile-files-img {
                    height: 140px;
                    width: 100%;
                    object-fit: fill;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                }

                .profile-document-bottom {
                    display: flex;
                    padding: 15px 20px;
                }

                .document-details {
                    width: calc(100% - 40px);
                }

                .document-name {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #1c212d;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .document-file-size-data {
                    display: flex;
                    margin-top: 5px;
                }

                .document-file-size {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #1c212d;
                }

                .document-file-data {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: rgba(28, 33, 45, 0.5);
                    border-left: 1px solid rgba(28, 33, 45, 0.5);
                    padding-left: 10px;
                    margin-left: 10px;
                }

                .document-file-icons {
                    margin-top: auto;
                    margin-bottom: auto;
                    margin-left: auto;

                    .download-button svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .work-detail-tab {
        .vault-tab table.table.table-inner-section {
            .project-description {
                font-weight: 600;
                font-size: 18px;
                line-height: 23px;
                color: #000000;
            }

            .project-name {
                font-size: 16px;
                line-height: 20px;
            }

            tbody {
                tr {
                    td {
                        .task-time {
                            font-weight: 600;
                            font-size: 20px;
                            line-height: 25px;
                        }

                        .created-on {
                            margin-left: 25px;
                            position: relative;

                            &::before {
                                content: "";
                                width: 6px;
                                height: 6px;
                                background: #00bc8b;
                                display: inline-block;
                                border-radius: 50%;
                                position: absolute;
                                left: -15px;
                                top: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .icon-btn {
        &.view-all-button.btn.btn-primary {
            color: #345bf8;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.modal-tabs {
    .modal-body {
        padding-bottom: 0 !important;
    }

    .project-add-icon {
        position: absolute;
        right: 15px;
        top: 15px;

        .project-btn.btn.btn-primary {
            font-size: 16px;
            width: 30px;
            height: 30px;
            min-width: 30px;
        }
    }

    .documents-tab-col {
        .vault-tab.employees-documents-tab {
            table.table {
                &.table-inner-section {
                    thead {
                        tr {
                            th {
                                margin-bottom: 10px;

                                &:first-child {
                                    padding-left: 5px;
                                }

                                &:last-child {
                                    padding-right: 5px;
                                }
                            }
                        }
                    }

                    tr {
                        td {
                            &:first-child {
                                padding-left: 5px;
                            }

                            &:last-child {
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }

            .common-class {
                font-weight: 400;
                font-size: 18px;
                line-height: 23px;
                color: #1c212d;

                &.common-name {
                    font-weight: 600;
                }

                &.detail-description {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    width: 200px;
                }
            }
        }
    }

    .modal-content {
        .modal-footer {
            padding-left: 0 !important;
            padding-right: 0 !important;
            bottom: 0px;
            position: sticky;
            top: unset;
            border-radius: 0;
        }
    }
}

.browse-file {
    label {
        padding: 5px;
    }

    input {
        bottom: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        /* z-index: 999;*/
        top: 0;
        left: 0;
        right: 0;
        padding: 10px;
        cursor: pointer;
        font-size: 0;
        opacity: 0;
    }
}

.table-scroll {
    .dataTables_wrapper {
        .dataTables_scroll,
        .dataTables_scroll * {
            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                display: block !important;
                scrollbar-width: thin;
            }

            &::-webkit-scrollbar-track {
                background: $scrollbar-track-bg;
                border-radius: 10px;
                scrollbar-width: thin;
            }

            &::-webkit-scrollbar-thumb {
                background: $scrollbar-thumb-bg;
                border-radius: 10px;
                scrollbar-width: thin;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $scrollbar-thumb-bg;
                scrollbar-width: thin;
            }

            .dataTables_scrollBody {
                border: none;
                scrollbar-width: thin;
            }
        }
    }
}

.leave-request-col,
.table-inner-section-col {
    .table-responsive {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            display: block !important;
            scrollbar-width: thin;
        }

        &::-webkit-scrollbar-track {
            background: $scrollbar-track-bg;
            border-radius: 10px;
            scrollbar-width: thin;
        }

        &::-webkit-scrollbar-thumb {
            background: $scrollbar-thumb-bg;
            border-radius: 10px;
            scrollbar-width: thin;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: $scrollbar-thumb-bg;
            scrollbar-width: thin;
        }
    }
}


.recent-task-item {
    overflow-x: auto;
    // &::-webkit-scrollbar{
    //   width: 5px;
    // }
    // &::-webkit-scrollbar-thumb{
    // }
}

.home-chart {
    margin-top: 20px;
    position: relative;

    svg {
        padding: 12px 0;
        border-radius: 20px;
        height: 400px;
    }

    .home-charts-buttons {
        position: absolute;
        z-index: 1;
        right: 25px;
        top: 14px;
        padding: 5px;
        background: #f3f6f9;
        border-radius: 5px;

        .selector {
            position: relative;
            display: flex;
            align-items: center;
        }

        .selecotr-item {
            position: relative;
        }

        .selector-item_radio {
            appearance: none;
            // display:none;
        }

        .selector-item_label {
            position: relative;
            padding: 8px 25px;
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
            text-align: center;
            color: rgba(28, 33, 45, 0.5);
            text-transform: capitalize;
            cursor: pointer;
            // &:before{
            //   content: "";
            //   border-right: 1px solid #cbcfd3;
            //   position: absolute;
            //   right: 0px;
            //   top: 8px;
            //   height: 20px;
            //   z-index: -1;
            // }
        }

        .selector-item_radio:checked + .selector-item_label {
            background: #ffffff;
            box-shadow: 0px 0px 10px rgba(128, 142, 167, 0.2);
            border-radius: 5px;
            color: #000000;
        }
    }
}

.tast-chart {
    .home-chart {
        margin-top: 5px;

        svg {
            height: 230px;
        }
    }
}

.pie-chart-profile-col {
    background: #f3f6f9;
    border-radius: 20px;
    height: 300px;

    .apexcharts-canvas {
        margin: 0 auto;
    }

    .pie-chart-header {
        padding: 15px;

        .pie-chart-titel {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            text-transform: uppercase;
            color: #000000;
        }
    }

    .apexcharts-legend {
        &.apexcharts-align-left {
            &.apx-legend-position-bottom {
                .apexcharts-legend-marker {
                    height: 10px !important;
                    width: 10px !important;
                    border-radius: 2px !important;
                }

                .apexcharts-legend-series {
                    width: 46%;
                }
            }
        }
    }
}

//01-03-2023
.headadmin {
    margin-left: 16px;
}

a.content-project-col {
    width: 90%;
}

.project-description {
    white-space: nowrap;
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.empspacedown {
    margin-right: 0 !important;
}

.empheadmain {
    padding-left: 20px !important;
}

.cursorpointer {
    cursor: pointer;
}

.project-description.project-desh-incontant {
    max-width: 220px;
}

.leave-page {
    .table-section-col {
        position: relative;
    }

    .custom-modal {
        .custom-modal-body {
            right: 20px;
            top: 80px;
        }
    }

    .vault-tab {
        .vault-description {
            max-width: 270px;
        }
    }
}

.custom-modal-footer {
    .btn-secondary:active {
        color: #fff;
    }
}

// .modal-new.modal-leave-request{
//   .tab-inner-box-right {
//     &.name{
//       width: calc(100% - 60px);
//       text-overflow: ellipsis;
//       overflow: hidden;
//       white-space: nowrap;
//       display: inline-block;
//     }
//     &.subject{
//       width: 100%;
//       text-overflow: ellipsis;
//       overflow: hidden;
//       white-space: nowrap;
//       display: inline-block !important;
//     }
//   }
// }

.form-control {
    &.down-arrow-icon {
        padding-right: 35px;
    }
}

.leave-request-type {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    width: 45px;
    height: 45px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    &.pl,
    &.lwp {
        background: #ff9e01;
    }

    &.cl {
        background: #345bf8;
    }

    &.casual {
        background: #f04141;
    }

    &.l_c_01 {
        background: #ffa07a;
    }
}

.leave-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    padding: 6px 17px;
    border-radius: 100px;
    text-transform: capitalize;
    min-width: 100px;
    text-align: center;

    &.approved {
        color: #009546;
        background: #e6fef1;
    }

    &.rejected {
        background: rgba(225, 51, 51, 0.1);
        color: #e13333;
    }

    &.pending {
        background: rgba(255, 158, 1, 0.1);
        color: #ff9e01;
    }

    &.cancelled {
        background: rgba(52, 91, 248, 0.1);
        color: #345bf8;
    }
}

.leave-status {
    margin-left: 15px;
    padding-left: 15px;
    position: relative;
}

.taskcontantboxes {
    display: flex;
    align-items: center;
}

.board-items {
    &::-webkit-scrollbar {
        display: block;
    }
}

.taskdetailtest {
    padding: 0 15px;
}

.height-adjust-modal-body {
    min-height: calc(100vh - 208px);
}


// hiren  10/07/2023

.all_user_at_hover {
    display: none;
}

.general-tab .cards .card-body .project-details .add-user:hover {
    cursor: pointer;
}

.general-tab .cards .card-body .project-details .add-user:hover .all_user_at_hover {
    display: grid;
    right: -40px;
    top: 100%;
    grid-template-columns: repeat(5,1fr);
    grid-gap: 15px;
    background: #fff;
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    transition: all .4s ease;
    transform: translateY(0px);
    animation: adduser .4s ease;
}

@keyframes adduser {
    from {
        transform: translateY(20px);
    }

    to {
        transform: translateY(0px);
    }
}


.modal-add-project .add_project_modal_selector .css-11unzgr {
    max-height: 250px;
}

.font-size-12px {
    font-size: 12px !important;
}


.leave-application-rejected input, .leave-application-approved input {
    pointer-events: none !important;
}

.leave-application-rejected textarea, .leave-application-approved textarea {
    pointer-events: none !important;
}


.rounded-circle {
    margin: 0px 5px;
}

.search-display {
    color : red;
}