.form-control {
  padding: 0 0.75rem;
  height: 44px;
  font-weight: 500;
  border-radius: 0.625rem;
  border-color: #cad3e0;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 0rem 0.75rem;
  height: 44px;
  // font-size: 0.825rem;
  border-radius: 0.625rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-floating > label {
  padding: 8px 0.75rem;
  // font-size: 0.825rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #000000;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 1;
  transform: scale(0.85) translateY(-0.65rem) translateX(0.15rem);
  background: $white-bg;
  display: inline-block;
  width: unset;
  padding: 0 5px;
  left: 4px;
  height: unset;
  line-height: 1.33;
}

.form-floating > .form-control-plaintext:-webkit-autofill,
.form-floating > .form-control:-webkit-autofill {
  padding-top: 0;
  padding-bottom: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: $white-bg !important;
}

.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  line-height: 18px;
  font-size: 14px;
  color: rgba(28, 33, 45, 0.5);
  font-weight: 400;
}


