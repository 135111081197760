@media (min-width: 576px) {
  .container {
    max-width: 564px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 940px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1080px;
  }
}

@media (min-width: 1366px) {
  .container {
    max-width: 1230px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1268px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1680px) {
  .container {
    max-width: 1345px;
  }
}

@media (min-width: 1792px) {
  .container {
    max-width: 1435px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1530px;
  }
  
}

@media (min-width: 2560px) {
  .container {
    max-width: 2048px;
  }
}

@media (min-width: 2880px) {
  .container {
    max-width: 2300px;
  }
}



@media only screen and (max-width:992px) {

}
@media only screen and (min-width:991px) and (max-width:1199px) {
  .custom-modal {
    .custom-modal-body{
      top: 64px;
    }
  }
  .leave-page {
    .custom-modal {
      .custom-modal-body{
        top: 60px;
      }
    }
  }
  .header {
    .header-search{
      max-width: 330px;
    }
    .header-project-col{
      width: 25%;
    }
  }
}

@media only screen and (min-width:1200px) and (max-width:1399px) {
  .header {
    .header-search{
      max-width: 320px;
    }
    .task-time-working {
      span{
        font-size: 26px;
        line-height: 32px; 
      }
    }
    .task-time-col{
      margin-left: 15px;
    }
    .dashboard-title {
      .dashboard-top-title{
        font-size: 14px;
       line-height: 17px;
      }
      .dashboard-main-title{
        font-size: 14px;
        line-height: 17px;
      }
    }
    .notification{
      .notification-col{
        margin-right: 15px;
        &:after{
          right: -15px;
        }
        svg {
          width: 20px;
          height: 20px;
          display: inline-block;
        }
      }
    }
    .header-project-col {
      margin-left: 15px;
      .project-description {
        font-size: 16px;
        line-height: 19px;
      }
      .project-name{
        font-size: 15px;
        line-height: 18px;
      }
    }
    .user-profile-img {
      width: 45px;
      height: 45px;
    }
  }
}

@media only screen and (min-width:1400px) and (max-width:1600px) {

  .header {
    .header-search{
      max-width: 330px;
    }
    .task-time-working {
      span{
        font-size: 26px;
        line-height: 32px; 
      }
    }
    .task-time-col{
      margin-left: 15px;
    }
    .dashboard-title {
      .dashboard-top-title{
        font-size: 17px;
       line-height: 20px;
      }
      .dashboard-main-title{
        font-size: 19px;
        line-height: 23px;
      }
    }
    .notification{
    .notification-col{
      margin-right: 20px;
      &:after{
        right: -20px;
      }
      svg {
        width: 25px;
        height: 25px;
        display: inline-block;
      }
    }
  }
    .header-project-col {
      margin-left: 15px;
    }  
    .user-profile-img {
      width: 50px;
      height: 50px;
    }
  }
}
// 01-03-2023 css

@media only screen and (max-width:1799px) {
  .icon-btn .btn-primary {
    display: flex;
    align-items: center;
    justify-content: center;
}
}

@media only screen and (max-width:1699px) {
  .cards .card-text span,
  .table-section-col .project-title,
  .general-tab .pro-details-description .about-project-title,
  .calendar-col .calendar-title-main {
    font-size: 28px;
    line-height: 36px;
}
.cards .card-title {
  font-size: 20px;
  line-height: 48px;
}
.fade.right-modal.modal-new .modal-content .modal-footer {
  padding-top: 20px;
}
.inner-page-title {
  font-size: 28px;
}
.calendar-col .col.cell .number {
  height: 35px;
  font-size: 17px;
  width: 35px;
  margin: 0 auto;
  line-height: 19px;
}
.custom-tabs .nav-link {
  padding: 7px 15px;
}
.page-title-left {
  font-size: 28px;
  line-height: 38px;
}
.fade.right-modal.modal-new.project-term-modal{
  max-width: 30%;
}
.profile-page .profile-section-left .profile-title {
  font-size: 28px;
  line-height: 36px;
}
.project-description.project-desh-incontant {
  max-width: 160px;
}
}
@media only screen and (max-width:1599px) {
  
}
@media only screen and (max-width:1499px) {
  .calendar-col .col.cell .number {
    height: 32px;
    font-size: 15px;
    width: 32px;
    line-height: 16px;
  }
  .project-cards .slick-slide {
    max-width: 430px;
}
.project-cards .card-body {
  min-height: 245px;
}
.project-cards .card .card-title {
  font-size: 20px;
}
.cards .card-text {
  font-size: 20px;
}
.cards .card-title {
  min-width: 70px;
  min-height: 70px;
  width: 70px;
  height: 70px;
}
.small-screen-width-adjust {
  width: 210px !important;
  max-width: 210px !important;
}
.project-description {
  width: 80%;
}
.cards .card-text span {
  font-size: 24px;
}
.projects-cards .card .card-img-center{
  width: 50px;
  height: 50px;
}
}
@media only screen and (max-width:1399px) {

  .task-page{
    .task-left-col {
      width: 100%;
      order: 2;
      padding-right: 0 !important;
    }
    .task-right-col {
      width: 100%;
      order: 1;
      margin-bottom: 15px;
      margin-top: 10px;
    }
  }


  .users .project-img{
    width: 35px;
    height: 35px;
  }
  .calendar-col .col.cell .number {
    height: 26px;
    width: 26px;
    font-size: 14px;
    line-height: 15px;
  }
  .cards .card-text span, .table-section-col .project-title {
    font-size: 24px;
}
.cards .card-title {
  font-size: 20px;
}
.recent-task-date-time .total-time {
  font-size: 18px;
}
.cards .card-body {
  padding: 25px 20px;
}
.project-title {
  font-size: 26px;
}
.calendar-col .col.cell .number {
  height: 26px;
}
.general-tab .cards .card-body .card-header-top .project-title {
  font-size: 22px;
}
.general-tab .activities-title {
  font-size: 22px;
}
.users .project-img {
  margin-right: -7px;
}
.table-col .table-text-common span {
  margin-left: 10px;
}
.vault-tab .table-text-common, .vault-tab .vault-description {
  font-size: 18px;
}
.modal-new table thead tr th {
  font-size: 18px;
}
.vault-tab table.table.table-inner-section .project-description {
  font-size: 18px;
}
.vault-tab table.table.table-inner-section thead tr th {
  font-size: 18px;
  white-space: nowrap;
}
.profile-page .profile-section-left .profile-contact .profile-contact-title,
.profile-page .profile-section-left .profile-bar-col .profile-bar-status-col .profile-bar-status {
  font-size: 22px;
}
.project-cards .card-white-bg .remove-hover{
  min-height: 119px;
}
.vault-page .password-dots {
  display: flex;
  flex-wrap: nowrap;
}
}
@media only screen and (max-width:1299px) {
  table.table.table-inner-section .project-description {
    font-size: 16px;
  }
  .table-inner-section-col {
    padding: 15px;
}
.calendar-col .col.cell .number {
  height: 25px;
  width: 25px;
}
}
@media only screen and (max-width:1199px) {
  .header .dashboard-title .dashboard-main-title {
    font-size: 18px;
}
.header .dashboard-title .dashboard-top-title {
  font-size: 14px;
}
.cards .card-text span, .table-section-col .project-title,
.general-tab .pro-details-description .about-project-title,
.calendar-col .calendar-title-main {
  font-size: 22px;
  line-height: 28px;
}
.cards .card-text {
  font-size: 18px;
}
.cards,
.table-section-col {
  padding: 10px;
}
.cards .card-title {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  font-size: 15px;
  margin-right: 10px;
}
.label-badges {
  font-size: 16px;
}
.cards .card-body {
  padding: 20px 10px;
}
.vertical-menu .vertical-list .vertical-menu-icon {
  height: 50px;
  width: 50px;
}
.fade.right-modal .modal-content .modal-header .modal-title {
  font-size: 20px;
}
.main-content {
  margin-left: 130px;
  width: calc(100% - 150px);
  min-height: calc(100vh - 120px);
}
.vertical-menu {
  width: 90px;
}
.header .search-input input {
  height: 50px;
}
.custom-tabs .nav-link {
  padding: 7px 10px;
  font-size: 16px;
}
.general-tab .cards .card-body .card-header-top .project-title {
  font-size: 18px;
}
.users .project-img {
  margin-right: -7px;
}
.calendar-col .col.cell .number {
  font-size: 12px;
  line-height: 12px;
}
.calendar-col .days .col {
  font-size: 11px;
}
.calendar-col .col.cell .number {
  height: 22px;
  width: 22px;
  margin: 0 auto;
}
.timeline-items .timeline-item .timeline-chat .timeline-commente {
  padding: 10px;
  font-size: 14px;
}
.fade.right-modal.modal-new.modal-add-project-user {
  max-width: 40%;
}
.project-cards .card .card-img-center {
  margin-right: 10px;
}
.project-cards .card .card-title {
  font-size: 16px;
}
.project-cards .project-cards-labels label {
  font-size: 14px;
  line-height: 16px;
}
.inner-search-bar .form-control {
  font-size: 16px;
}
.table-section-col .headadminmain {
  order: 0;
}
.table-section-col .headmanutxt {
  order: 2;
  margin-top: 15px !important;
}
.table-section-col .headlastserch{
  order: 1;
}
.custom-tabs.custom-tabs-grey {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  display: grid !important;
}
.custom-tabs .nav-link {
  padding: 7px 47px;
}
.col.cell {
  padding: 0;
}
.projectsecadmin {
  order: 0;
}
.projectsectab {
  order: 2;
  margin-top: 20px;
}
.projectsecserch {
  order: 1;
}
.custom-tabs {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
  // margin-top: 20px;
}
.fade.right-modal {
  max-width: 70%;
}
.vault-tab table.table.table-inner-section thead tr th,
.vault-tab table.table.table-inner-section thead tr th {
  font-size: 18px;
  white-space: nowrap;
}
.vault-tab table.table.table-inner-section .project-description {
  font-size: 16px;
}
.vault-tab .table-text-common, .vault-tab .vault-description {
  font-size: 16px;
}
.fade.right-modal.modal-new.project-term-modal {
  max-width: 50%;
}
// .tbl-info .dataTables_length {
//   order: 0;
//   width: 50%;
//   flex: 0 0 auto;
// }
// .tbl-info .dataTables_info {
//   order: 1;
//   width: 50%;
//   flex: 0 0 auto;
// }
// .tbl-info .paggination {
//   order: 2;
//   width: 100%;
//   flex: 0 0 auto;
//   display: flex;
//   margin-top: 20px;
// }
// .tbl-info .length-info {
//   display: flex;
//   flex-wrap: wrap;
// }
.profile-page .profile-section-left .profile-contact .contact-item-icon {
  min-width: 45px;
  min-height: 45px;
  width: 45px;
  height: 45px;
}
.profile-page .profile-section-left .profile-contact .contact-item-top {
  font-size: 16px;
}
.profile-page .empl-information .empl-infor-top {
  font-size: 16px;
}
.profile-page .custom-tabs.custom-tabs-grey {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.profile-page .custom-tabs .nav-link {
  padding: 7px 10px;
}
.board-tab .board-hight {
  height: calc(100vh - 384px);
}
}
@media only screen and (max-width:1023px) {
  .header {
    padding-top: 14px;
}
.header .notification .notification-col:after {
  right: -20px;
}
.header .task-time-working span {
  font-size: 26px;
  line-height: 36px;
}
.header .dashboard-title .dashboard-main-title {
  font-size: 16px;
}
.fade.right-modal {
  max-width: 100%;
}
.fade.right-modal .modal-content {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.fade.right-modal.modal-new .modal-content .modal-footer {
  padding-left: 26px;
}

.project-cards .card .card-title,
.vault-tab table.table.table-inner-section .project-description {
  font-size: 18px;
}

.task-page .cards .card-text span {
  font-size: 20px;
}
.task-page .cards .card-title {
  font-size: 22px;
  min-width: 46px;
  min-height: 46px;
  width: 36px;
  height: 46px;
}
.vault-user-text {
  width: 230px;
  max-width: 230px;
}
.setting-page .nav-pills .nav-link {
  font-size: 16px;
}
.project-add-icon .project-btn.btn.btn-primary {
  width: 35px;
  height: 35px;
  min-width: 35px;
}
.board-tab .board-hight {
  height: calc(100vh - 390px);
}
}
@media only screen and (max-width:991px) {
  .header .task-time-working span {
    font-size: 22px;
    line-height: 30px;
}
.header .header-project-col {
  margin-left: 10px;
}
.header .search-input .icon {
  top: 40%;
  height: 50px;
  width: 50px;
}
.header .search-input input {
  padding: 0 0 0 40px;
  font-size: 16px;
}
.header .dashboard-title .user-profile-img {
  height: 50px;
  width: 50px;
}
.header .pause-play-button {
  width: 30px;
  height: 30px;
  min-width: 30px;
}
.header .task-time-col {
  margin: 11px 0 11px 10px;
}
.header .notification .notification-col:after {
  right: -12px;
}
.header {
  width: calc(100% - 150px);
}
.headadmin {
  margin-left: 0;
}
.project-cards .card {
  margin-bottom: 20px;
}
.fade.right-modal.modal-new .modal-content .modal-footer {
  padding-left: 35px;
}
.inner-search-bar .form-control {
  font-size: 14px;
}
.inner-page-title {
  font-size: 24px;
}
.leave-request-col {
  margin-bottom: 20px;
}
.fade.right-modal.modal-new.modal-add-project-user {
  max-width: 100%;
}
.custom-tabs .nav-link {
  padding: 7px 31px;
}
.table-section-col .headmanutxt {
  margin-top: 0px !important; 
}
.files-tab .files-tab-items {
  grid-template-columns: 49% 49%;
}
.vault-tab table.table tr th .username {
  padding-left: 0;
}
.vault-tab table.table.table-inner-section thead tr th {
  font-size: 18px;
}
.calendar-col .col.cell .number {
  height: 30px;
  width: 30px;
  margin: 0 auto;
}
.calendar-col .days .col {
  font-size: 16px;
}
.page-title-left {
  font-size: 24px;
}
.fade.right-modal.modal-new.project-term-modal {
  max-width: 100%;
}
.setting-page:before {
  width: 20px;
}
.setting-page .nav-pills .nav-link {
  padding: 11px 7px 11px 6px;
}
.setting-page .nav-pills .nav-link {
  font-size: 14px;
}
.setting-page .setting-left-sidebar-title {
  font-size: 18px;
}
.setting-page:before {
  width: 35px;
}
.setting-page .setting-left-sidebar-title {
  padding-left: 6px;
}
}
@media only screen and (max-width:800px) {
  .header .dashboard-title .dashboard-main-title {
    font-size: 14px;
}
.header .dashboard-title .dashboard-top-title {
  font-size: 12px;
}
.fade.right-modal.modal-new .modal-content .modal-footer {
  padding-left: 20px;
}
.custom-tabs .nav-link {
  padding: 7px 28px;
}
.table-col .table-text-common span {
  margin-left: 10px;
}
.status-icon {
  margin-left: 10px;
}
.users .project-img {
  margin-right: -7px;
}
}


@media only screen and (min-width:1700px) and (max-width:1919px) {
  .calendar-col .col.cell .number {
    height: 39px;
    font-size: 16px;
    line-height: 19px;
  }
}