.modal {
  .modal-content {
    border: none;
    form{
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .modal-header {
      padding: 0.75rem 1.11rem;
      border-bottom: 0;
      .modal-title {
        font-size: 1.25rem;

        .title-span {
          color: $modal-title-color;
        }
      }
    }
    .modal-body {
      padding: 1rem 0.5rem;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        display: block !important;
        scrollbar-width: thin;
      }
      
      &::-webkit-scrollbar-track {
        background: $scrollbar-track-bg;
        border-radius: 10px;
        scrollbar-width: thin;
      }
      
      &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-bg;
        border-radius: 10px;
        scrollbar-width: thin;
      }
      
      &::-webkit-scrollbar-thumb:hover {
        background: $scrollbar-thumb-bg;
        scrollbar-width: thin;
      }
    }

    .modal-footer {
      // padding: 0 1rem 0.75rem;
      // padding: 0 1rem 0;
      padding: 1rem 0;
      width: 100%;
      background: #ffffff;
      border: none;
      flex-direction: row-reverse;
      justify-content: end;
    }
  }
}

.modal-backdrop.fade{
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  opacity: 0.5;
}

.modal-tabs{
  .nav-tabs {
    box-shadow: none;
    margin-bottom: 30px;
    .nav-link{
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      text-transform: capitalize;
      color: #7e7e7e;
      margin-right: 30px;
      padding:0 0 5px 0;
      &:hover{
        color: #1C212D;
        box-shadow: inset 0 -1px 0 #000;
        opacity: 1;
      }
      &.active{
        color: #1C212D;
        font-weight: 700;
        box-shadow: inset 0 -1px 0 #000;
        &:hover{
          color: #1C212D;
          font-weight: 700;
          box-shadow: inset 0 -1px 0 #000;
        }
      }
    }
  }
}